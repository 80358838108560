
import { getParticipantImageUrl } from '@/services/participant-service';
import { defineStore } from 'pinia';
import { useAuthenticationStore } from './authentication-store';
import { Role } from '@/common/constants/enums';
import { getSquareParticipantDetailsAndUpdate } from '@api/services/command/default/ParticipantService';
import { DateTime } from 'luxon';

export interface SquareParticipant {
  avatar: string;
  about?: string;
  hasProfileImage: boolean;
  username?: string;
  id: number;
  guid: string;
  dateModified?: DateTime;
  lastLoginDate?: DateTime;
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  role: Role;
  addressLine1?: string;
  addressLine2?: string;
  zipPostalCode?: string;
  city?: string;
  country?: string;
  stateRegion?: string;
  communicationEmail?: string;
  communicationEmailCode?: string;
  dateMigrationModalShown?: DateTime;
  migrationDismissed: boolean;
}

export const defaultAvatar = '/images/default-profile.svg';

export const useParticipantStore = defineStore('participant', {
  state: () => ({
    participant: {
      id: 0,
      about: '',
      avatar: defaultAvatar,
      hasProfileImage: false,
      username: '',
      guid: '',
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      role: Role.Participant, // participant role as default
      addressLine1: '',
      addressLine2: '',
      zipPostalCode: '',
      city: '',
      country: '',
      stateRegion: '',
      communicationEmail: '',
      communicationEmailCode: '',
      migrationDismissed: false,
    } as SquareParticipant,
    /** List of functions to be executed once the participant details have been loaded.
     * Consider using the {@link executeIfLoaded} function instead.
     */
    executeIfLoadedFunctions: [] as Array<() => void | Promise<void>>,
    isLoadingDone: false,
    shouldStartTour: false,
  }),

  actions: {
    init() {
      const authStore = useAuthenticationStore();
      authStore.loginFunctions.push(this.setSquareParticipantDetails);
    },
    async setSquareParticipantDetails() {
      const details = await getSquareParticipantDetailsAndUpdate();

      this.shouldStartTour = !this.shouldStartTour && !details.detail?.platformTourStarted;

      const avatar = details.detail?.hasProfileImage
        ? getParticipantImageUrl(details.detail.guid, details.detail?.dateModified)
        : this.participant.avatar;
      if (details.detail) {
        this.participant = {
          ...details.detail,
          avatar,
          migrationDismissed: false,
        };
        this.participant.migrationDismissed = localStorage.getItem(`${this.participant.guid}_mt`) !== null;
      }

      // Only execute functions on first time
      if (!this.isLoadingDone && this.executeIfLoadedFunctions) {
        this.executeIfLoadedFunctions.forEach((fn) => fn());
        this.executeIfLoadedFunctions = [];
        this.isLoadingDone = true;
      }
    },
    /** Execute the function when the participant details are loaded.
     * If the details are already loaded, the function will execute immediately
     * @param { Function } fn: function to be executed
     * @returns { void }
     */
    executeIfLoaded(fn: () => void): void {
      if (this.isLoadingDone) {
        fn();
      } else {
        this.executeIfLoadedFunctions.push(fn);
      }
    },
    setDateMigrationDialogShown(dateMigrationDialogShown: DateTime) {
      this.participant.dateMigrationModalShown = dateMigrationDialogShown;
    },
    setMigrationDismissed(value: boolean) {
      this.participant.migrationDismissed = value;
      if (value) {
        localStorage.setItem(`${this.participant.guid}_mt`, '1');
      } else {
        localStorage.removeItem(`${this.participant.guid}_mt`);
      }
    },
  },

  getters: {
    isFirstLogin: (state) => state.participant.lastLoginDate == null,
  },
});
