import {
  GetAccessForActivityResponse,
  HaveUsersAccessToActivityResponse,
  HaveUsersAccessToActivityRequest,
  HasUserAccessToActivitiesRequest,
  ValidateSequencedActivityResponse,
  ActivitySequenceListItemResponse,
  NextSequencedActivityRedirectInfoResponse,
  ListOrphanItemResponse,
  ActivityDetailItemResponse,
  ActivityResearchForMessageDetailItemResponse,
  SquareActivityStatus,
  SquareActivityType,
  ListActivityItemResponse,
  SelectedSegmentsOption,
  ConversationSortOption,
  SurveyActivityResponse,
  ScreenerSurveysForOpenRegistrationResponse,
  ProgramActivitiesResponse,
  ActivitySegmentationSelectionResponse,
  ActivityQuantResearchDetailItemResponse,
  ActivityQualResearchDetailItemResponse,
  ListProfileActivityDetailItemResponse,
  GetTestActivityDetailsResponse,
  ListRecruitmentPartnersResponse,
  GetRecruitmentPartnerDetailResponse,
  ListActivityPartnerResponse,
  ActivityReportDataResponse,
  CheckActivityNameIsDuplicateResponse,
  IntakeCallbackResponse,
  GetMemberListResponse,
  ActivityCompleteRedirectResponse,
  ActivityDateFilterRange,
  GetActivityCountByTypeResponse,
  DecipherConfigurationResponse,
  SquareActivityFormat,
  GetRespondentRequest,
  ActivitySquareParticipantListItem,
  ActivityVisibilityType,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getAccessForActivity = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAccessForActivityResponse[]>('/ActivityService/GetAccessForActivity', { activityGuid }, config);

export const haveUsersAccessToActivity = (request: HaveUsersAccessToActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<HaveUsersAccessToActivityResponse[]>(
    '/ActivityService/HaveUsersAccessToActivity',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const excludedUsersAccessFromActivity = (request: HaveUsersAccessToActivityRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<HaveUsersAccessToActivityResponse[]>(
    '/ActivityService/ExcludedUsersAccessFromActivity',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const getActivityAccessForSquareParticipant = (request: HasUserAccessToActivitiesRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string[]>(
    '/ActivityService/GetActivityAccessForSquareParticipant',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const validateSequencedActivityCreate = (parentActivityGuid: string, childActivityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ValidateSequencedActivityResponse>('/ActivityService/ValidateSequencedActivityCreate', { parentActivityGuid, childActivityGuid }, config);

export const validateSequencedActivityRemove = (childActivityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ValidateSequencedActivityResponse>('/ActivityService/ValidateSequencedActivityRemove', { childActivityGuid }, config);

export const getSequenceForActivity = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySequenceListItemResponse[]>('/ActivityService/GetSequenceForActivity', { activityGuid }, config);

export const isSequencedActivityUnlocked = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ActivityService/IsSequencedActivityUnlocked', { activityGuid }, config);

export const getNextSequencedActivityRedirectInfo = (finishedActivityGuid?: string, finishedSampleGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<NextSequencedActivityRedirectInfoResponse>('/ActivityService/GetNextSequencedActivityRedirectInfo', { finishedActivityGuid, finishedSampleGuid }, config);

export const isSequencedActivitySample = (sampleGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ActivityService/IsSequencedActivitySample', { sampleGuid }, config);

export const getFirstIncompleteActivityGuidInSequence = (activityGuid?: string, sampleGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/ActivityService/GetFirstIncompleteActivityGuidInSequence', { activityGuid, sampleGuid }, config);

export const listOrphan = (programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListOrphanItemResponse>('/ActivityService/ListOrphan', { programGuid }, config);

export const selectActivity = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityDetailItemResponse>('/ActivityService/SelectActivity', { guid }, config);

export const getActivityDetailsForMessage = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityResearchForMessageDetailItemResponse>('/ActivityService/GetActivityDetailsForMessage', { guid }, config);

export const getSquareActivityStatus = (request: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareActivityStatus>('/ActivityService/GetSquareActivityStatus', { request }, config);

export const getSquareActivityType = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareActivityType>('/ActivityService/GetSquareActivityType', { activityGuid }, config);

export const listActivitiesInSquare = (guid: string, pageNumber: number, limit: number, selectedSegmentsOption: SelectedSegmentsOption, sortBy: ConversationSortOption, type?: SquareActivityType[], order?: string, forceReindex?: boolean, noUnreadConversationsFeatureEnabled?: boolean, keyword?: string, startDate?: DateTime, endDate?: DateTime, selectedStimuliOptions?: number[], selectedAnnotationsOptions?: number[], selectedThemes?: string[], selectedMembers?: string[], selectedSegments?: string[], selectedRatings?: number[], tagsSelected?: string[], hashtagsSelected?: string[], statuses?: SquareActivityStatus[], selectedModerationStatusOptions?: number[], selectedProbeQuestions?: string[], isDiscussionNew?: boolean, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListActivityItemResponse>('/ActivityService/ListActivitiesInSquare', { guid, pageNumber, limit, selectedSegmentsOption, sortBy, type, order, forceReindex, noUnreadConversationsFeatureEnabled, keyword, startDate, endDate, selectedStimuliOptions, selectedAnnotationsOptions, selectedThemes, selectedMembers, selectedSegments, selectedRatings, tagsSelected, hashtagsSelected, statuses, selectedModerationStatusOptions, selectedProbeQuestions, isDiscussionNew }, config);

export const listSurveyActivitiesForCurrentSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SurveyActivityResponse[]>('/ActivityService/ListSurveyActivitiesForCurrentSquare', undefined, config);

export const listScreenerSurveysForOpenRegistration = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ScreenerSurveysForOpenRegistrationResponse[]>('/ActivityService/ListScreenerSurveysForOpenRegistration', undefined, config);

export const isOpenRegistrationScreenerActiveForSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ActivityService/IsOpenRegistrationScreenerActiveForSquare', undefined, config);

export const getOpenRegistrationScreenerLink = (squareGuid: string, countrySegmentGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/ActivityService/GetOpenRegistrationScreenerLink', { squareGuid, countrySegmentGuid }, config);

export const listActivitiesInProgram = (programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ProgramActivitiesResponse>('/ActivityService/ListActivitiesInProgram', { programGuid }, config);

export const listActivitiesInProgramForReporting = (programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ProgramActivitiesResponse>('/ActivityService/ListActivitiesInProgramForReporting', { programGuid }, config);

export const listActivitySegmentationSelection = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySegmentationSelectionResponse>('/ActivityService/ListActivitySegmentationSelection', { guid }, config);

export const selectActivityQuantResearch = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityQuantResearchDetailItemResponse>('/ActivityService/SelectActivityQuantResearch', { guid }, config);

export const selectActivityQualResearch = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityQualResearchDetailItemResponse>('/ActivityService/SelectActivityQualResearch', { guid }, config);

export const getBuildActivityQualResearch = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityQualResearchDetailItemResponse>('/ActivityService/GetBuildActivityQualResearch', { guid }, config);

export const listParticipantProfileActivities = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListProfileActivityDetailItemResponse>('/ActivityService/ListParticipantProfileActivities', { guid }, config);

export const listParticipantsProfileActivities = (participantGuids?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[][]>('/ActivityService/ListParticipantsProfileActivities', { participantGuids }, config);

export const getTestActivityDetails = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetTestActivityDetailsResponse>('/ActivityService/GetTestActivityDetails', { activityGuid }, config);

export const listParticipantProgress = (participantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<number>('/ActivityService/ListParticipantProgress', { participantGuid }, config);

export const listParticipantsProgress = (participantGuids?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<Record<string, number>>('/ActivityService/ListParticipantsProgress', { participantGuids }, config);

export const getActivityGuidsOfSameSquare = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/ActivityService/GetActivityGuidsOfSameSquare', { activityGuid }, config);

export const listActivityRecruitmentPartners = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListRecruitmentPartnersResponse>('/ActivityService/ListActivityRecruitmentPartners', { activityGuid }, config);

export const selectActivityRecruitmentPartnerDetail = (recruitmentPartnerGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetRecruitmentPartnerDetailResponse>('/ActivityService/SelectActivityRecruitmentPartnerDetail', { recruitmentPartnerGuid }, config);

export const listActivityPartners = (activityGuid: string, searchText?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListActivityPartnerResponse>('/ActivityService/ListActivityPartners', { activityGuid, searchText }, config);

export const reportData = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityReportDataResponse>('/ActivityService/ReportData', { activityGuid }, config);

export const checkActivityNameIsDuplicate = (name?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<CheckActivityNameIsDuplicateResponse>('/ActivityService/CheckActivityNameIsDuplicate', { name }, config);

export const selectIntakeCallback = (intakeGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<IntakeCallbackResponse>('/ActivityService/SelectIntakeCallback', { intakeGuid }, config);

export const getMemberList = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetMemberListResponse>('/ActivityService/GetMemberList', { activityGuid }, config);

export const getActiveActivities = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/ActivityService/GetActiveActivities', undefined, config);

export const getActivityCompleteRedirect = (sampleGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityCompleteRedirectResponse>('/ActivityService/GetActivityCompleteRedirect', { sampleGuid }, config);

export const getFilterDateRangeForActivity = (activityType?: SquareActivityType[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityDateFilterRange>('/ActivityService/GetFilterDateRangeForActivity', { activityType }, config);

export const getActivityMaxStartDate = (activityType?: SquareActivityType, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<DateTime>('/ActivityService/GetActivityMaxStartDate', { activityType }, config);

export const getActivityCountByType = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetActivityCountByTypeResponse>('/ActivityService/GetActivityCountByType', undefined, config);

export const getIsDecipherProfileConfiguredCorrectly = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<DecipherConfigurationResponse>('/ActivityService/GetIsDecipherProfileConfiguredCorrectly', { activityGuid }, config);

export const getIsDecipherSurveyPublished = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ActivityService/GetIsDecipherSurveyPublished', { activityGuid }, config);

export const getRespondentObject = (sampleGuid: string, format: SquareActivityFormat, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<object>('/ActivityService/GetRespondentObject', { sampleGuid, format }, config);

export const getRespondent = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<object>('/ActivityService/GetRespondent', undefined, config);

export const getMentionSuggestions = (activityGuid: string, visibilityType: ActivityVisibilityType, parentPostAuthorGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySquareParticipantListItem[]>('/ActivityService/GetMentionSuggestions', { activityGuid, visibilityType, parentPostAuthorGuid }, config);

export const getHashtagGuids = (hashtags?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/ActivityService/GetHashtagGuids', { hashtags }, config);

export const getHashtags = (hashtags?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<Record<string, string>>('/ActivityService/GetHashtags', { hashtags }, config);

export const getTagGuids = (tags?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/ActivityService/GetTagGuids', { tags }, config);

export const getTags = (tags?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<Record<string, string>>('/ActivityService/GetTags', { tags }, config);
