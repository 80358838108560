import { RouteRecordRaw } from 'vue-router';
import { autoLoginRoute } from './autologin.route';
import { changePasswordRoute } from './change-password.route';
import { confirmEmailRoute } from './confirm-email.route';
import { externalLinkRoute } from './external-link.route';
import { loginErrorRoute } from './login-error.route';
import { loginRoute } from './login.route';
import { mainRoute } from './main.route';
import { nonMainRoute } from './non-main.route';
import { maintenanceRoute } from './maintenance.route';
import { notFoundRoute } from './not-found.route';
import { reactivateRoute } from './reactivate.route';
import { registrationRoute } from './registration.route';
import { resetPasswordRoute } from './reset-password.route';
import { ssoRoute } from './sso.route';
import { unsubscribeRoute } from './unsubscribe.route';
import { logoutRoute } from './logout.route';
import { signInErrorRoute } from './signin-error.route';
import { lastStepRoute } from './last-step.route';
import { signUpRoute } from './signup.route';
import { adActivationRoute } from './ad-activation.route';
import { AdSignInRoute } from './ad-signin.route';
import { adEmailMigrationRoute } from './ad-emailmigration.route';
import { adUpdateAccountRoute } from './ad-updateaccount.route';
import { adMigrationRoute } from './ad-migration.route';
import { adSignUpRoute } from './ad-signup.route';

const routes: RouteRecordRaw[] = [
  mainRoute,
  nonMainRoute,
  autoLoginRoute,
  changePasswordRoute,
  confirmEmailRoute,
  externalLinkRoute,
  loginErrorRoute,
  loginRoute,
  maintenanceRoute,
  reactivateRoute,
  registrationRoute,
  adActivationRoute,
  AdSignInRoute,
  adSignUpRoute,
  adEmailMigrationRoute,
  adUpdateAccountRoute,
  adMigrationRoute,
  signInErrorRoute,
  lastStepRoute,
  signUpRoute,
  resetPasswordRoute,
  ssoRoute,
  unsubscribeRoute,
  logoutRoute,
  // Make sure to keep the notFoundRoute at the end since this is our 'catch all' route.
  notFoundRoute,
];

export default routes;
