import { RouteNames } from '@/router/routes/route-names.enum';
import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import subdomainGuard from './global-guards/subdomain-guard';
import authorizedGuard from './global-guards/authorized-guard';
import pageAccessGuard from './global-guards/page-access-guard';
import documentTitleGuard from './global-guards/document-title-guard';
import splashPageGuard from './global-guards/splash-page-guard';
import forceMobileAppGuard from './global-guards/force-mobile-app-guard';
import migrationGuard from './global-guards/migration-guard';

import { BASE_URL } from '@/common/env.config';
import { onStart, onSuccess } from '@/common/utils/platform-health';
import redirectFromWebviewGuard from './global-guards/redirect-from-webview-guard';
import startupGuard from './global-guards/startup-guard';
import { watch } from 'vue';
import { isInitialized } from '@/common/utils/startup';

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return;
    }
    if(to.name === RouteNames.News && from.name === RouteNames.NewsDetail) {
      return {
        el: `.news-cards__card--${from.params.cardGuid}`,
        top: 70, // position the card under the header
      };
    }
    return { top: 0 };
  },
});

router.beforeEach(subdomainGuard);
const removeStartupGuard = router.beforeEach(startupGuard);
router.beforeEach(forceMobileAppGuard);
router.beforeEach(authorizedGuard);
router.beforeEach(pageAccessGuard);
router.beforeEach(splashPageGuard);
router.beforeEach(redirectFromWebviewGuard);
router.beforeEach(migrationGuard);
router.beforeResolve(documentTitleGuard);
router.beforeEach(onStart);
router.afterEach(onSuccess);

// Remove the startup guard once the app is initialized. It only has to run once.
// Also stop watching for changes to isInitialized.
const stopStartupWatch = watch(isInitialized, (initialized) => {
  if (initialized) {
    removeStartupGuard();
    stopStartupWatch();
  }
});

export default router;
