export enum ConversationFocusType {
  Text = 1,
  Visual = 2,
}

export enum RedirectForumType {
  SingleTopic = 0,
  Room = 1,
  NewTopics = 2,
}

export enum ActivityContributionType {
  Standard = 0,
  Diary = 1,
}

export enum StimulusType {
  Photo = 0,
  Video = 1,
  Youtube = 2,
  Vimeo = 3,
  Attachment = 4,
}

export enum SquareChallengeFilter {
  All = 0,
  Todo = 1,
  Completed = 2,
}

export enum CardType {
  None = 0,
  ManualRewards = 1,
  AutomaticSavingUp = 2,
  ManualSavingUp = 3,
}

export enum Feature {
  None = 0,
  CheckMarket = 1,
  SavingUpRewards = 2,
  DividedDiscussionTypes = 3,
  Decipher = 4,
  ScoutDiscussionTypes = 5,
  Quota = 6,
  Confirmit = 7,
  CurationFeedbackCircle = 9,
  AnalysisAndReporting = 10,
  Discussion20New = 11,
  ActivityTargetCompletion = 13,
  ConsentMapping = 14,
  SOITargeting = 15,
  NoUnreadConversations = 16,
  AccessData = 18,
  MediaGallery = 19,
  DecipherDiary = 21,
  Captcha = 23,
  Observation = 24,
  ActivityJobIdEverywhere = 25,
  AzureAdB2CLogin = 26,
  FooterRestrictions = 27,
  SyncQualButtonHomepage = 28,
  ChatGPT = 29,
  Pulse = 30,
  CustomDiscussionImport = 41,
}

export enum ConversationQuestionType {
  None = 0,
  Text = 1,
  Image = 2,
  Video = 3,
  ImageAndVideo = 4,
  Attachment = 5,
  Freestyle = 6,
}

export enum SquareParticipantChangeRequestType {
  ForgotPassword = 1,
  ChangeEmail = 2,
  Subscription = 3,
  ConfirmEmail = 4,
}

export enum GeneralCommunicationType {
  Other = 0,
  Email = 1,
  HomePage = 2,
  CustomAppNotification = 3,
}

export enum MessageType {
  Engagement = 0,
  ResearchActivity = 1,
  QualActivity = 2,
  ProfileActivity = 3,
}

export enum RewardPartnerType {
  None = 0,
  TangoCardNonEur = 1,
  Paypal = 2,
  Cadora = 3,
  TangoCardEurFrance = 4,
  TangoCardEurGermany = 5,
  TangoCardEurItaly = 6,
  TangoCardEurSpain = 7,
  Other = 8,
  Emag = 9,
  Tremendous = 10,
  PanoramaResearch = 11,
  Ovation = 12,
  UWinIwin = 13,
  Guanaitong = 14,
  GiftPay = 15,
  SavingUpRewardsCorrection = 99,
}

export enum IdeaStreamProjectTarget {
  CustomPage = 0,
  EngagementMessage = 1,
  Newsletter = 2,
}

export enum SuspendReason {
  NotSuspended = -1,
  Unknown = 0,
  SuspendedPanelParticipant = 1,
  JobIdRemoval = 2,
  NotActiveInNavison = 3,
  SuspendedLowQuality = 4,
  SuspendedOther = 5,
  BlackListedFraudulent = 6,
  BlacklistedSpam = 7,
  BlacklistedBot = 8,
  BlacklistedOther = 9,
  RightToBeForgotten = 10,
  SourceConsentExpiration = 11,
  SwitchingLanguage = 12,
}

export enum PlatformNoticeTargetTypes {
  None = 0,
  Participant = 1,
  Human8 = 2,
  Moderator = 4,
  Observer = 8,
}

export enum SquareUrlStatus {
  Disabled = 0,
  Pending = 1,
  Enabled = 2,
}

export enum SquareParticipantAction {
  None = 0,
  ShouldLoginNextTime = 1,
}

export enum InactivityStatus {
  Active = 0,
  AlmostInactive = 1,
  Dormant = 2,
  Inactive = 3,
}

export enum ChannelType {
  None = 0,
  CommunicationCard = 1,
  EmailNewsletter = 2,
  CommunicationText = 4,
  ResearchActivityChallenge = 5,
  ResearchActivityEmail = 6,
  ResearchActivityReminder = 7,
  ResearchActivityIncomplete = 8,
  ResearchActivityChallengeHome = 9,
  IncentiveCard = 10,
  ResearchActivityMobileApp = 11,
  CommunicationNews = 12,
  Notification = 13,
  NotificationUpdate = 14,
  CommunicationNewsHome = 15,
  DiscussionThread = 16,
  PageIntro = 17,
  CustomAppNotification = 18,
  DigestEmail = 19,
  PageCta = 20,
  ProfilePage = 21,
}

export enum Role {
  NoRoleYet = 0,
  Human8 = 1,
  Observer = 2,
  Participant = 3,
  ProfessionalAdmin = 7,
  ClientAdmin = 9,
  ClientEditor = 10,
  ClientApi = 4,
  ServiceApi = 5,
  AzureDevOps = 8,
  SsoUser = 6,
  All = 1000,
}

export enum ClientVideoStorage {
  AzureMediaServices = 0,
  Mux = 1,
  Human8MediaService = 2,
}

export enum ClientVideoQuality {
  Low = 0,
  Medium = 1,
  High = 2,
}

export enum Area {
  Undefined = 0,
  MainPages = 1,
  HelpCenter = 2,
  Footer = 3,
  Incentives = 4,
  Splash = 5,
}

export enum SquareParticipantHistoryType {
  None = 0,
  RegistrationStatus = 1,
  AdObjectId = 2,
  Email = 3,
  Username = 4,
  Field = 5,
  Migrating = 6,
}

export enum NotificationStatus {
  NotRead = 1,
  Read = 2,
}

export enum SurveyOutcomeCode {
  None = 0,
  Qualified = 1,
  Screened = 2,
  QuotaFull = 3,
}

export enum SampleStatus {
  Inserted = 0,
  ToDo = 2,
  InProgress = 3,
  Completed = 4,
}

export enum ClientStatus {
  Active = 1,
  Inactive = 2,
  Disabled = 3,
}

export enum WinnerStatus {
  Approved = 1,
  NotApproved = 2,
  Notified = 3,
  DetailsConfirmed = 4,
  OfferedToRedeem = 5,
}

export enum ConversationStimuliType {
  Image = 0,
  Video = 1,
  Youtube = 2,
  Vimeo = 3,
}

export enum ConversationStimuliStatus {
  Saved = 0,
  Removed = 1,
  New = 2,
  SavingAsDraft = 3,
}

export enum AutoLoginStatus {
  NotNeeded = 0,
  Failed = 1,
  Succeeded = 2,
}

export enum SquareActivityType {
  None = 0,
  ConfirmitSurvey = 1,
  QualitativeResearch = 2,
  CheckMarketSurvey = 3,
  PublicQualitativeResearch = 4,
  PrivateQualitativeResearch = 5,
  DecipherSurvey = 6,
  PublicScoutResearch = 7,
  PrivateScoutResearch = 8,
  Discussion20Research = 9,
  DecipherDiarySurvey = 11,
  Observation = 12,
}

export enum DataFormatType {
  Json = 0,
  NoJson = 1,
  Xml = 2,
}

export enum LogType {
  Log = 0,
  Audit = 1,
  Mail = 2,
  Export = 3,
  ManualReward = 4,
  Upload = 5,
}

export enum SegmentAction {
  Add = 0,
  Remove = 1,
}

export enum LogSeverity {
  Debug = 0,
  Info = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export enum SquareActivityFormat {
  None = 0,
  Screener = 1,
  Profile = 2,
  Research = 3,
}

export enum PlatformNoticeType {
  None = 0,
  DefaultMaintenance = 1,
  CustomNotice = 2,
}

export enum SquareActivationQuestionType {
  Text = 0,
  Checkbox = 1,
  MultiCheckbox = 2,
  Radio = 3,
  List = 4,
  ListCheckbox = 5,
}

export enum InSitesSquareLogoutRedirectType {
  SquareInfo = 0,
  Activities = 1,
  SquareLogin = 2,
  ActivityWizard = 3,
  ActivityConversation = 4,
}

export enum ActivitySequenceRole {
  Unsequenced = 0,
  Parent = 1,
  Child = 2,
}

export enum StimuliUploadState {
  Empty = 0,
  Uploading = 1,
  Encoding = 2,
  Valid = 3,
  Invalid = 4,
  InvalidAndNotified = 5,
  Transcribing = 6,
}

export enum NotificationType {
  ProfileActivityAvailable = 0,
  QualResearchActivityAvailable = 1,
  QuantResearchActivityAvailable = 2,
  QualResearchAlmostClosed = 3,
  QuantResearchAlmostClosed = 4,
  DiscussionMention = 5,
  QualResearchActivityUpdate = 6,
  DiscussionReply = 7,
  DiscussionLike = 8,
  ForumMention = 9,
  ForumReply = 10,
  ForumLike = 11,
  PlatformNotification = 12,
  NextActivityInSequenceLaunched = 13,
  DiscussionActivityComplete = 14,
  SavingUpRewardsEarned = 15,
  NewProbingQuestion = 16,
  NewOptionalProbingQuestion = 17,
  SavingUpRewardsCorrectionApplied = 18,
  RewardsAvailableForRedemption = 19,
  DiscussionMentions = 2000,
  DiscussionReplies = 200,
  DiscussionLikes = 20,
  DiscussionMentionReplyLike = 1110,
  DiscussionMentionRepliesLike = 1210,
  DiscussionMentionReplyLikes = 1120,
  DiscussionMentionRepliesLikes = 1220,
  DiscussionMentionsReplyLike = 2110,
  DiscussionMentionsRepliesLike = 2210,
  DiscussionMentionsReplyLikes = 2120,
  DiscussionMentionsRepliesLikes = 2220,
  DiscussionMentionReply = 1100,
  DiscussionMentionReplies = 1200,
  DiscussionMentionsReply = 2100,
  DiscussionMentionsReplies = 2200,
  DiscussionMentionLike = 1010,
  DiscussionMentionLikes = 1020,
  DiscussionMentionsLike = 2010,
  DiscussionMentionsLikes = 2020,
  DiscussionReplyLike = 110,
  DiscussionRepliesLike = 210,
  DiscussionReplyLikes = 120,
  DiscussionRepliesLikes = 220,
  ForumMentions = 4000,
  ForumReplies = 400,
  ForumLikes = 40,
  ForumMentionReplyLike = 3330,
  ForumMentionRepliesLike = 3430,
  ForumMentionReplyLikes = 3340,
  ForumMentionRepliesLikes = 3440,
  ForumMentionsReplyLike = 4330,
  ForumMentionsRepliesLike = 4430,
  ForumMentionsReplyLikes = 4340,
  ForumMentionsRepliesLikes = 4440,
  ForumMentionReply = 3300,
  ForumMentionReplies = 3400,
  ForumMentionsReply = 4300,
  ForumMentionsReplies = 4400,
  ForumMentionLike = 3030,
  ForumMentionLikes = 3040,
  ForumMentionsLike = 4030,
  ForumMentionsLikes = 4040,
  ForumReplyLike = 330,
  ForumRepliesLike = 430,
  ForumReplyLikes = 340,
  ForumRepliesLikes = 440,
  MoreSavingUpRewardsEarned = 6000,
  NewLoungeTopic = 6001,
  DiscussionMentionForAdmin = 7000,
  NewReplyWithAnswerRequired = 7001,
  ReplyAccepted = 7002,
  FailedStimuliUpload = 7003,
  SquareParticipantAlmostInactive = 7004,
}

export enum ResearcherLoginRedirectType {
  Activities = 0,
  ActivityWizard = 1,
  ActivityConversation = 2,
}

export enum SampleRemovedReasons {
  None = 0,
  Suspended = 1,
  Unsubscribed = 2,
  NotInTarget = 4,
  ActivitySegmentTargetFull = 8,
  ActivityAccess = 16,
}

export enum SquareStatus {
  Draft = 0,
  Scheduled = 1,
  Active = 2,
  Closed = 3,
  Archived = 4,
  Deleted = 5,
}

export enum PageType {
  Home = 1,
  Activities = 2,
  Forum = 3,
  Ideabox = 4,
  News = 5,
  MainPageCustom = 6,
  FAQ = 7,
  PlatformTour = 8,
  Contact = 9,
  HelpCenterCustom = 10,
  FooterCustom = 11,
  IncentivesCustom = 12,
  MyIncentive = 13,
  RewardPolicy = 14,
  SavingUpRewardPolicy = 15,
  MyCookieSettings = 16,
  CookiePolicy = 17,
  Profile = 18,
}

export enum ServiceDeskQuestionType {
  None = 0,
  MyAccount = 1,
  MyIncentives = 2,
  Technical = 3,
  Other = 4,
  DataDeletionRequest = 5,
  Activity = 6,
}

export enum FaqCategoryType {
  None = 0,
  ManualRewards = 1,
  SavingUpRewards = 2,
  ManualSavingUpRewards = 3,
}

export enum RaffleType {
  Instant = 1,
  Periodic = 2,
  Manual = 3,
  SavingUp = 4,
}

export enum ConversationSortOption {
  Chronological = 0,
  MostRecent = 1,
  MostLiked = 2,
}

export enum RegistrationStatus {
  SingleOptInNoEmail = 0,
  SingleOptIn = 1,
  SingleOptInReminder = 2,
  DoubleOptIn = 3,
  DoubleOptInReminder = 4,
  Active = 5,
  Unsubscribed = 6,
  Anonymized = 7,
}

export enum CardStatus {
  None = 0,
  New = 1,
  Update = 2,
}

export enum NotificationCategory {
  None = 0,
  Activity = 1,
  Profile = 2,
  Page = 3,
  Home = 4,
}

export enum ConversationElementType {
  None = 0,
  Topic = 1,
  Question = 2,
  Answer = 3,
  Reply = 4,
  IndividualUpdatePost = 5,
}

export enum SquareEventTargetKind {
  ResearchActivity = 1,
  EngagementCard = 2,
  NewsCard = 3,
}

export enum ResearchMethod {
  Quantitative = 1,
  Qualitative = 2,
}

export enum CommunicationStatus {
  Draft = 0,
  Scheduled = 1,
  Active = 2,
  Closed = 3,
}

export enum SplashMessageType {
  Undefined = 0,
  Agreement = 1,
  SquareClosure = 2,
}

export enum ActivityQualCompleteCriteriaType {
  Word = 1,
  Image = 2,
  Video = 3,
  OnlineMedia = 4,
  Attachment = 5,
}

export enum AccountType {
  SquareAccount = 0,
  SocialLogin = 1,
}

export enum ServiceDeskType {
  MyAccount = 1,
  MyIncentives = 2,
  Technical = 3,
  Other = 4,
  ParticipantSupport = 13,
  DataDeletionRequest = 32,
  Activity = 33,
}

export enum FileType {
  Image = 1,
  Video = 2,
  Attachment = 3,
}

export enum SquareParticipantCreditType {
  None = 0,
  Default = 1,
  SavingUp = 2,
  Leftover = 3,
  Invalidate = 4,
}

export enum DisplayStyles {
  None = 0,
  GreyedOut = 1,
  Hidden = 2,
  Focused = 4,
  PlaceHolder = 8,
}

export enum DiscussionSort {
  Chronological = 0,
  MostRecent = 1,
  MostLiked = 2,
}

export enum QuestionTypes {
  Text = 1,
  Attachment = 2,
  Photo = 4,
  Video = 8,
  PhotoVideo = 12,
  Freestyle = 15,
}

export enum AnnotationType {
  None = 0,
  Hashtag = 1,
  Highlight = 2,
  Like = 3,
  Mention = 4,
  Note = 5,
  Quote = 6,
  Rating = 7,
  Tag = 8,
}

export enum ModerationStatus {
  Unmoderated = 0,
  UpdateRequired = 1,
  Accepted = 2,
  Other = 3,
}

