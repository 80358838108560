import {
  MasterAppListResponse,
  SquareActivitiesResponse,
  GetAppleAppIdResponse,
  GetAppAndroidPackageFingerprintsResponse,
  GetAppCustomUriDetailsResponse,
  ListSquareItemResponse,
  SquareDetailItem,
  SquareDetailItemResponse,
  CountryResponse,
  GetSquareAppScreenerInfoResponse,
  SquareSelectionResponse,
  ConnectProjectDetailItem,
  MobileAppGetLabelsResponse,
  ListAllSquaresItemResponse,
  SquareDesignDetailItemResponse,
  GetSquareActivationQuestionsResponse,
  SquareAppDesignResponse,
  ListSquareTimelineCardsResponse,
  ICardListItem,
  SquareAppActivitiesResponse,
  ActivitySelectionResponse,
  CheckSquareNameIsDuplicateResponse,
  SquareParticipantForLoggedInInSitesUserResponse,
  ActivityThemeListItemResponse,
  ImpersonateUserListResponse,
  ChannelType,
  SplashMessageDetailItem,
  ListJobItemResponse,
  SquareUrlDto,
  SquareStatus,
  GetSquareJobsResponse,
  GetSquaresWithMobileScreenerForAppResponse,
  SquareLandingPageConfigResponse,
  SquareSocialLoginConfigResponse,
  UserMigrationConfigResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const listApps = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<MasterAppListResponse>('/SquareService/ListApps', undefined, config);

export const squareActivities = (squareUrl?: string, squareId?: number, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareActivitiesResponse>('/SquareService/SquareActivities', { squareUrl, squareId }, config);

export const getAppleAppId = (squareUrl?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAppleAppIdResponse>('/SquareService/GetAppleAppId', { squareUrl }, config);

export const getAppAndroidPackageFingerprints = (squareUrl?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAppAndroidPackageFingerprintsResponse>('/SquareService/GetAppAndroidPackageFingerprints', { squareUrl }, config);

export const getAppCustomUriDetails = (squareUrl?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAppCustomUriDetailsResponse>('/SquareService/GetAppCustomUriDetails', { squareUrl }, config);

export const listSquare = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListSquareItemResponse>('/SquareService/ListSquare', { guid }, config);

export const getSquareDetails = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareDetailItem>('/SquareService/GetSquareDetails', { squareGuid }, config);

export const selectSquare = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareDetailItemResponse>('/SquareService/SelectSquare', { guid }, config);

export const getSquareCountries = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<CountryResponse[]>('/SquareService/GetSquareCountries', { squareGuid }, config);

export const getSsoIv = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetSsoIv', undefined, config);

export const getSquareAppScreenerInfo = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetSquareAppScreenerInfoResponse>('/SquareService/GetSquareAppScreenerInfo', undefined, config);

export const getSsoKey = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetSsoKey', undefined, config);

export const squareSelection = (programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareSelectionResponse>('/SquareService/SquareSelection', { programGuid }, config);

export const getSquareConnectProject = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ConnectProjectDetailItem>('/SquareService/GetSquareConnectProject', { squareGuid }, config);

export const getSquareListForObserverOrModerator = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListSquareItemResponse>('/SquareService/GetSquareListForObserverOrModerator', undefined, config);

export const listMobileLabels = (culture?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<MobileAppGetLabelsResponse>('/SquareService/ListMobileLabels', { culture }, config);

export const listAllSquares = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListAllSquaresItemResponse>('/SquareService/ListAllSquares', undefined, config);

export const selectSquareDesign = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareDesignDetailItemResponse>('/SquareService/SelectSquareDesign', { squareGuid }, config);

export const getSquareActivationQuestions = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetSquareActivationQuestionsResponse>('/SquareService/GetSquareActivationQuestions', { squareGuid }, config);

export const selectSquareMobileAppDesign = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareAppDesignResponse>('/SquareService/SelectSquareMobileAppDesign', undefined, config);

export const listSquareTimelineCards = (lastCardGuid?: string, newsPages?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListSquareTimelineCardsResponse>('/SquareService/ListSquareTimelineCards', { lastCardGuid, newsPages }, config);

export const listSquareChallengeSequences = (included?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ICardListItem[]>('/SquareService/ListSquareChallengeSequences', { included }, config);

export const getTodoNotificationsCount = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<number>('/SquareService/GetTodoNotificationsCount', undefined, config);

export const getTodoNotificationsCountForSquareParticipantGuid = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<number>('/SquareService/GetTodoNotificationsCountForSquareParticipantGuid', { squareParticipantGuid }, config);

export const getSquareMobileAppUserActivities = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareAppActivitiesResponse>('/SquareService/GetSquareMobileAppUserActivities', undefined, config);

export const activitySelection = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySelectionResponse>('/SquareService/ActivitySelection', undefined, config);

export const researchActivitySelection = (includingChildActivityGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySelectionResponse>('/SquareService/ResearchActivitySelection', { includingChildActivityGuid }, config);

export const profileActivitySelection = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivitySelectionResponse>('/SquareService/ProfileActivitySelection', undefined, config);

export const checkSquareNameIsDuplicate = (name?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<CheckSquareNameIsDuplicateResponse>('/SquareService/CheckSquareNameIsDuplicate', { name }, config);

export const getSquareParticipantForLoggedInInSitesUser = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantForLoggedInInSitesUserResponse>('/SquareService/GetSquareParticipantForLoggedInInSitesUser', undefined, config);

export const getUrlWithIdeaStreamProjectId = (ideaStreamUrl?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetUrlWithIdeaStreamProjectId', { ideaStreamUrl }, config);

export const getThemes = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityThemeListItemResponse[]>('/SquareService/GetThemes', undefined, config);

export const getThemesForActivityConfig = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityThemeListItemResponse[]>('/SquareService/GetThemesForActivityConfig', { activityGuid }, config);

export const getSquareStartDate = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<DateTime>('/SquareService/GetSquareStartDate', undefined, config);

export const getCustomCss = (isEmail: boolean, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetCustomCSS', { isEmail }, config);

export const getSquareLanguageDirection = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/SquareService/GetSquareLanguageDirection', undefined, config);

export const getThemeList = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ActivityThemeListItemResponse[]>('/SquareService/GetThemeList', undefined, config);

export const getImpersonateUserList = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ImpersonateUserListResponse[]>('/SquareService/GetImpersonateUserList', undefined, config);

export const getExternalLink = (guid: string, activityGuid: string, channel: ChannelType, username?: string, squareName?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetExternalLink', { guid, activityGuid, channel, username, squareName }, config);

export const getSplashDataToShow = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SplashMessageDetailItem>('/SquareService/GetSplashDataToShow', undefined, config);

export const getSplashDataToEdit = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SplashMessageDetailItem>('/SquareService/GetSplashDataToEdit', undefined, config);

export const selectionSquareJob = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListJobItemResponse>('/SquareService/SelectionSquareJob', undefined, config);

export const getActivatedSquareParticipantUsernamesAsync = (squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/SquareService/GetActivatedSquareParticipantUsernamesAsync', { squareGuid }, config);

export const getAllSquareUrls = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareUrlDto[]>('/SquareService/GetAllSquareUrls', undefined, config);

export const getSquareStatus = (squareGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareStatus>('/SquareService/GetSquareStatus', { squareGuid }, config);

export const getSquareJobs = (programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetSquareJobsResponse[]>('/SquareService/GetSquareJobs', { programGuid }, config);

export const getSquareAdActivationLinkFromUrl = (url?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/SquareService/GetSquareAdActivationLinkFromUrl', { url }, config);

export const getSquaresWithMobileScreenerForSquareApp = (isTest: boolean, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetSquaresWithMobileScreenerForAppResponse[]>('/SquareService/GetSquaresWithMobileScreenerForSquareApp', { isTest }, config);

export const getSquareLandingPageConfig = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareLandingPageConfigResponse>('/SquareService/GetSquareLandingPageConfig', undefined, config);

export const getSquareSocialLoginConfig = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareSocialLoginConfigResponse>('/SquareService/GetSquareSocialLoginConfig', undefined, config);

export const getUserMigrationConfig = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserMigrationConfigResponse>('/SquareService/GetUserMigrationConfig', undefined, config);
