import { __awaiter } from "tslib";
import * as angular from 'angular';
import { Subject } from 'rxjs';
import { PlatformNoticeOption } from '../models/platformNoticeOption';
import { CacheService } from '../services/cache.service';
export class AuthService {
    constructor(httpService, $state, _, $q, $rootScope, ssoService, ssocheckService, serverConstants, projectService, squareFactory, userService, participantFactory, $location, __env) {
        this.httpService = httpService;
        this.$state = $state;
        this._ = _;
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.ssoService = ssoService;
        this.ssocheckService = ssocheckService;
        this.serverConstants = serverConstants;
        this.projectService = projectService;
        this.squareFactory = squareFactory;
        this.userService = userService;
        this.participantFactory = participantFactory;
        this.$location = $location;
        this.__env = __env;
        this._impersonate = null;
        this.notifications = this.$rootScope.$new(true);
        this.isAuthorized = this._.memoize(() => {
            if (this.ssocheckService.hasSsoToken()) {
                return this.$q.resolve(false);
            }
            return this.$q.resolve(CacheService.getCacheValue(this.userService.JWT_KEY) != null);
        });
        this.impersonatedUserChange = new Subject();
        this.getTokenInfo = this._.memoize(() => this.httpService.get({
            url: '/AuthorizationService/GetTokenInfo',
        }).then((response) => response.data));
        this.getToConversationParamFromURI = () => {
            const parameterName = 'go_to_conversation=';
            const redirectTypeParameterName = 'redirect_type=';
            let index = this.$location.url().indexOf(parameterName);
            let conversationGuid = null;
            let redirectType = null;
            if (index !== -1) {
                const parameter = this.$location.url().substring(this.$location.url().indexOf(parameterName));
                conversationGuid = parameter.substring(parameterName.length, parameter.indexOf('&') !== -1 ? parameter.indexOf('&') : parameter.length);
            }
            index = this.$location.url().indexOf(redirectTypeParameterName);
            if (index !== -1) {
                const type = this.$location.url().substring(this.$location.url().indexOf(redirectTypeParameterName));
                redirectType = parseInt(type.substring(redirectTypeParameterName.length, type.indexOf('&') !== -1 ? type.indexOf('&') : type.length), 10);
            }
            return {
                conversationGuid,
                redirectType,
            };
        };
        this.isAuthorized().then((isAuth) => __awaiter(this, void 0, void 0, function* () {
            if (isAuth) {
                this.httpService.useToken(this.userService.getToken());
                this.loadTokenInfo();
                this.notifications.$emit('login');
                this.setImpersonate();
            }
        }));
    }
    on(ev, callback) {
        return this.notifications.$on(ev, callback);
    }
    setToken(jwt) {
        CacheService.setCache({ key: this.userService.JWT_KEY, value: jwt });
        this.httpService.useToken(jwt);
        this.notifications.$emit('login');
        this.isAuthorized.cache = new this._.memoize.Cache();
        this.getTokenInfo.cache = new this._.memoize.Cache();
        return this.loadTokenInfo();
    }
    getOrRenewSignalRToken() {
        return this.httpService.get({
            url: '/AuthorizationService/GetTokenForSignalR',
        }).then((response) => response.data);
    }
    authenticate(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            credentials.grant_type = 'password';
            if (!credentials.captchaResponse) {
                delete credentials.captchaResponse;
            }
            const username = credentials.username;
            const authStatus = yield this.httpService.get({
                url: '/AuthorizationService/GetStatusForAuthorization',
                params: { username },
            });
            if (!authStatus
                || !authStatus.data
                || !authStatus.data.Valid
                || !authStatus.data.DefaultLogin) {
                return { success: false, activateWarning: false };
            }
            const authResponse = yield this.httpService.post({
                url: '/oauth2/token',
                data: angular.fromJson(credentials),
                transformRequest: (obj) => {
                    const str = [];
                    this._.forEach(Object.keys(obj), (p) => {
                        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
                    });
                    return str.join('&');
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            yield this.setToken(authResponse.data.access_token);
            const ssoLoginEnabled = this.__env.useSsoLoginPage;
            return {
                success: true,
                activateWarning: authStatus.data.Migrated === false && ssoLoginEnabled === true,
            };
        });
    }
    get impersonate() {
        if (this._impersonate === undefined) {
            const jsImpersonate = window.localStorage.getItem('Impersonating') || window.sessionStorage.getItem('Impersonating');
            this._impersonate = !jsImpersonate ? null : JSON.parse(jsImpersonate);
            this.httpService.useImpersonate(this._impersonate);
        }
        return this._impersonate;
    }
    set impersonate(impersonate) {
        if (!impersonate) {
            this._impersonate = null;
            window.localStorage.removeItem('Impersonating');
            window.sessionStorage.removeItem('Impersonating');
        }
        else {
            window.localStorage.setItem('Impersonating', JSON.stringify(impersonate));
            window.sessionStorage.setItem('Impersonating', JSON.stringify(impersonate));
            this._impersonate = impersonate;
        }
        this.httpService.useImpersonate(this._impersonate);
        this.impersonatedUserChange.next(impersonate);
    }
    get isImpersonating() {
        return this.impersonate
            && this.impersonate.squareParticipantGuid
            && this.impersonate.squareParticipantGuid !== '';
    }
    static get impersonate() {
        const jsImpersonate = window.sessionStorage.getItem('Impersonating') || window.sessionStorage.getItem('Impersonating');
        if (!jsImpersonate) {
            return null;
        }
        return JSON.parse(jsImpersonate);
    }
    shouldDisplayCaptcha() {
        return this.httpService.get({
            url: '/AuthorizationService/ShouldShowCaptcha',
        }).then((response) => response.data.ShowCaptcha);
    }
    autologin(sampleGuid) {
        return this.httpService.get({
            url: '/authorizationservice/autologin',
            params: { sampleGuid },
        }).then((response) => {
            if (response.data.Status === this.serverConstants.autoLoginStatusConstants.succeeded) {
                this.setToken(response.data.Token);
            }
            else if (response.data.Status === this.serverConstants.autoLoginStatusConstants.failed) {
                return this.$q.reject(response.data);
            }
            return response.data;
        });
    }
    goToAdminAndLogIn() {
        window.location.href = this.getSquareAdminUrlForModeratorAndObserver();
    }
    getSwitchInterfaceUrl() {
        const fromswitchinterface = true;
        let url;
        if (this.userService.hasModeratorRole() || this.userService.hasObserverRole()) {
            url = this.getSquareAdminUrlForModeratorAndObserver(fromswitchinterface);
        }
        else {
            url = this.getSquareAdminSignOutAndRedirectUrlForInsitesUser();
        }
        return url;
    }
    getWizardUrl(activityGuid, step) {
        const additionalParams = {
            activityGuid,
            step,
        };
        const redirectType = (this.userService.hasModeratorRole() || this.userService.hasObserverRole()) ?
            this.serverConstants.researcherLoginRedirectTypeConstants.activityWizard :
            this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activityWizard;
        return this.getUrl(redirectType, additionalParams);
    }
    getActivityConversationUrl(activityGuid, replyGuid, isEdit) {
        const additionalParams = {
            activityGuid,
            replyGuid,
            isEdit
        };
        const redirectType = (this.userService.hasModeratorRole() || this.userService.hasObserverRole()) ?
            this.serverConstants.researcherLoginRedirectTypeConstants.activityConversation :
            this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activityConversation;
        return this.getUrl(redirectType, additionalParams);
    }
    getUrl(redirectType, additionalParams) {
        if (this.userService.hasModeratorRole() || this.userService.hasObserverRole()) {
            let url = `${this.projectService.getSquareAdminUrl()}/researcherlogin?token=${encodeURIComponent(this.userService.getToken())}&switchinterface=true&redirectType=${redirectType}`;
            for (const key in additionalParams) {
                if (Object.prototype.hasOwnProperty.call(additionalParams, key) && key !== 'token' && key !== 'switchinterface' && key !== 'redirectType') {
                    const value = additionalParams[key];
                    url = `${url}&${key}=${value}`;
                }
            }
            return url;
        }
        return this.getSquareAdminSignOutUrl(redirectType, additionalParams);
    }
    startAdminSsoLogin(redirectUrl) {
        this.ssoService.saveRedirectUrl(redirectUrl);
        this.ssoService.startAdminSsoLogin();
    }
    getOnBehalfOfGuidFromURI() {
        const parameterName = 'on_behalf_of=';
        const index = this.$location.url().indexOf(parameterName);
        let onBehalfOfGuid;
        if (index !== -1) {
            const parameter = this.$location.url().substring(this.$location.url().indexOf(parameterName));
            onBehalfOfGuid = parameter.substring(parameterName.length, parameter.indexOf('&') !== -1 ? parameter.indexOf('&') : parameter.length);
        }
        return onBehalfOfGuid;
    }
    getFromObserverParamFromURI() {
        return this.$location.search().from_observer === '1';
    }
    setImpersonate() {
        const onBehalfOfGuid = this.getOnBehalfOfGuidFromURI();
        if (onBehalfOfGuid === '') {
            this.impersonate = null;
        }
        else if (onBehalfOfGuid) {
            this.getUsernameForParticipant(onBehalfOfGuid).then((username) => {
                this.impersonate = {
                    squareParticipantGuid: onBehalfOfGuid,
                    username,
                };
            });
        }
    }
    signOut(shouldRedirect_1) {
        return __awaiter(this, arguments, void 0, function* (shouldRedirect, logOutOnAllSquares = true) {
            if (this.userService.getToken()) {
                if (!this.userService.isMobileAppUser()) {
                    if (logOutOnAllSquares && !this.userService.hasParticipantRole()) {
                        yield this.logOutOnAllSquares();
                    }
                    CacheService.removeCacheValue(this.userService.JWT_KEY);
                    CacheService.removeCacheValue(`${PlatformNoticeOption.KEY}_${this.userService.email()}`);
                    this.impersonate = null;
                    this.httpService.useToken(null);
                    this.notifications.$emit('logout');
                    this.isAuthorized.cache = new this._.memoize.Cache();
                    this.participantFactory.setParticipantDefaultAvatar();
                    if (shouldRedirect) {
                        this.$state.go('container.login');
                    }
                }
            }
        });
    }
    logOutOnAllSquares() {
        return __awaiter(this, void 0, void 0, function* () {
            this.httpService.post({
                url: '/ParticipantService/LogoutOnAllSquares',
            });
        });
    }
    getUsernameForParticipant(squareParticipantGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const squareParticipantDetails = yield this.getSquareParticipantDetails(squareParticipantGuid);
            const detail = squareParticipantDetails.data.Detail;
            return detail ? detail.Username : '';
        });
    }
    getSquareParticipantDetails(squareParticipantGuid) {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantDetails',
            params: {
                squareParticipantGuid,
            },
        });
    }
    getSquareParticipantDetailsAndUpdate() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/ParticipantService/GetSquareParticipantDetailsAndUpdate',
            });
            this.participantFactory.setSquareParticipantDetails(response.data.Detail);
        });
    }
    squareToIdeaStreamSsoLogin(params) {
        return this.httpService.post({
            url: '/AuthorizationService/SquareToIdeaStreamSsoLogin',
            params,
        });
    }
    getSquareAdminSignOutAndRedirectUrlForInsitesUser() {
        let redirectType = this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.squareInfo;
        if (this.squareFactory.IsCompleted) {
            redirectType = this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activities;
        }
        return this.getSquareAdminSignOutUrl(redirectType);
    }
    getSquareAdminSignOutUrl(redirectType, additionalParams) {
        let url = `${this.projectService.getSquareAdminUrl()}/squarelogout?token=${encodeURIComponent(this.userService.getToken())}&redirectType=${redirectType}`;
        if (additionalParams) {
            for (const key in additionalParams) {
                if (Object.prototype.hasOwnProperty.call(additionalParams, key) && key !== 'token' && key !== 'redirectType') {
                    const value = additionalParams[key];
                    url = `${url}&${key}=${value}`;
                }
            }
        }
        return url;
    }
    getSquareAdminUrlForModeratorAndObserver(fromswitchinterface = false, activateWarning = false) {
        let switchInterfaceParam = '';
        if (fromswitchinterface) {
            switchInterfaceParam = '&switchinterface=true';
        }
        let activateMessage = '';
        if (activateWarning) {
            activateMessage = '&activatemsg=true';
        }
        return `${this.projectService.getSquareAdminUrl()}/researcherlogin?token=` +
            `${encodeURIComponent(this.userService.getToken())}${switchInterfaceParam}${activateMessage}`;
    }
    getUserToken() {
        this.loadTokenInfo();
        return this.userService.getToken();
    }
    loadTokenInfo() {
        return this.getTokenInfo().then((info) => {
            this.userService.setTokenInfo(info);
        });
    }
}
AuthService.$inject = [
    'httpService', '$state', '_', '$q', '$rootScope',
    'ssoService', 'ssocheckService', 'serverConstants',
    'projectService', 'squareFactory', 'userService', 'participantFactory',
    '$location', '__env',
];
