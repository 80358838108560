import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useParticipantStore } from '@/store/participant-store';
import { Role } from '@/common/constants/enums';
import { validate as isUuid } from 'uuid';

/*
  NOTE! this route is designed to be handled by both
    - authenticated square participants
      for migration
    - not authenticated square participants
      for email sign up

  for now, we do NOT use it for not authenticated square participants
*/

const AdEmailMigrationPage = () => import('@/pages/ad-emailmigration-page.vue');

export const adEmailMigrationRoute: RouteRecordRaw = {
  name: RouteNames.ADEmailMigration,
  path: '/migrate',
  component: AdEmailMigrationPage,
  meta: {
    title: '',
    requireLogin: false,
    hideFooter: false,
  },
  beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // we allow routing only if AD square but not AD user yet
    const squareStore = useSquareStore();
    const authStore = useAuthenticationStore();
    if (!squareStore.info.adLogin
      || authStore.isAdUser) {
      return { name: RouteNames.Home };
    }
    // if not authorized, we need to have a valid identity in url and come from the AD activation
    if (!authStore.isAuthorized) {
      const identity = to.query.identity;
      if (from.name !== RouteNames.AdSignUp || !identity || !isUuid(identity.toString())) {
        return { name: RouteNames.Home };
      }
    } else {
      const participantStore = useParticipantStore();
      if (participantStore.participant.role === Role.Human8) {
        return { name: RouteNames.Home };
      }
    }
    return true;
  },
};
