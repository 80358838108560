import { AutoLoginStatus, Feature, Role } from '@/common/constants/enums';
import { AUTHENTICATION_BASE, JWT_KEY } from '@/common/env.config';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { getCacheValue, removeCacheValue, setCache } from '@ui/common/utils/cache';
import { post, useImpersonate, useToken } from '@/services/http-service';
import { hasSsoToken } from '@/common/utils/sso';
import { onBehalfOfGuidFromURI } from '@/common/utils/url';
import { defineStore } from 'pinia';
import { Credentials, Impersonate } from './authentication-store.types';
import { removeUserFromGroup } from '@/common/utils/events';
import { useSquareStore } from '@/store/square-store';
import { squareParticipantDetails } from '@api/services/query/default/ParticipantService';
import { hideCaptcha } from '@/services/captcha-service';
import { SquareStatus } from '@/common/constants/enums';
import { getSquareAdminUrlForModeratorAndObserver } from '@/common/utils/url';
import { autoLogin, getStatusForAuthorization, getTokenInfo } from '@api/services/query/default/AuthorizationService';
import { GetTokenInfoResponse } from '@api/models/query';
import { logoutOnAllSquares } from '@api/services/command/default/ParticipantService';
import { isFeatureEnabledForSquare } from '@/services/feature-service';
import { setMigrationDialogClosed } from '@/router/global-guards/migration-guard';

const impersonatingStorageKey = 'Impersonating';

type TokenInfo = GetTokenInfoResponse
export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    impersonate: null as Impersonate | null,
    token: null as string | null,
    tokenInfo: null as TokenInfo | null,
    loginFunctions: [] as Array<() => void | Promise<void>>,
    logoutFunctions: [] as Array<() => void>,
    squareParticipantChangedFunctions: [] as Array<() => void>,
    shouldShowMobileAppPage: false,
    adObjectId: null as string | null,
  }),

  actions: {
    setAdObjectId(value: string | null) {
      this.adObjectId = value;
      if (this.adObjectId) {
        setCache({ key: 'adid', value: this.adObjectId });
      } else {
        removeCacheValue('adid');
      }
    },

    async init() {
      await this.setTokenFromLocalStorage();
      this.initImpersonateFromLocalStorage();
      this.adObjectId = getCacheValue('adid');
    },

    async setTokenFromLocalStorage() {
      const token = getCacheValue<string>(JWT_KEY);
      if (token) {
        await this.setToken(token, false);
      }
    },

    async setToken(token: string, shouldSetCache = true) {
      this.token = token;

      if (shouldSetCache) {
        setCache({ key: JWT_KEY, value: token });
      }

      useToken(token);

      try {
        await this.loadTokenInfo();
        for (const fn of this.loginFunctions) {
          await fn();
        }
        setMigrationDialogClosed();
      } catch {
        this.token = null;
        removeCacheValue(JWT_KEY);
      }
    },

    async loadTokenInfo() {
      const tokenInfo = await getTokenInfo();
      this.tokenInfo = tokenInfo;
      this.squareParticipantChangedFunctions.forEach((fn) => fn());
      if (this.tokenInfo?.adObjectId) {
        removeCacheValue('adid');
      }
    },

    async setImpersonate(impersonateUserGuid?: string) {
      const onBehalfOfGuid = impersonateUserGuid ?? onBehalfOfGuidFromURI();
      if (!onBehalfOfGuid || onBehalfOfGuid === '') {
        this.impersonate = null;
        localStorage.removeItem(impersonatingStorageKey);
        sessionStorage.removeItem(impersonatingStorageKey);
      } else {
        const username = await getUsernameForParticipant(onBehalfOfGuid);
        const impersonate = {
          squareParticipantGuid: onBehalfOfGuid,
          username,
        };
        this.impersonate = impersonate;
        localStorage.setItem(impersonatingStorageKey, JSON.stringify(impersonate));
        sessionStorage.setItem(impersonatingStorageKey, JSON.stringify(impersonate));
      }
      useImpersonate();
    },

    initImpersonateFromLocalStorage() {
      const data = localStorage.getItem(impersonatingStorageKey) || localStorage.getItem(impersonatingStorageKey);
      if (!data) {
        return;
      }

      this.impersonate = JSON.parse(data);
      useImpersonate();
    },

    async authenticate(credentials: Credentials): Promise<{ success: boolean; activateWarning: boolean }> {
      const authResponse = await this.getAccessToken(credentials);
      if (authResponse.token !== '') {
        await this.setToken(authResponse.token);
        await this.setImpersonate();
      }
      return {
        success: authResponse.token !== '',
        activateWarning: authResponse.activateWarning,
      };
    },

    async getAccessToken(credentials: Credentials) {
      credentials.grant_type = 'password';
      if (!credentials.captchaResponse) {
        delete credentials.captchaResponse;
      }

      const username = credentials.username;
      const authStatus = await getStatusForAuthorization(username);
      if (!authStatus
        || !authStatus.valid
        || !authStatus.defaultLogin) {
        return { token: '', activateWarning: false };
      }

      const transformRequest = (obj: Record<string, string | number | boolean>) => {
        const str: string[] = [];
        Object.keys(obj).forEach((k) => {
          str.push(`${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`);
        });
        return str.join('&');
      };
      const isAzureAdB2CEnabled = await isFeatureEnabledForSquare(Feature.AzureAdB2CLogin);

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      const response = await post<{ access_token: string }>(
        '/oauth2/token', credentials, undefined, { headers, transformRequest });
      return {
        token: response.access_token,
        activateWarning: !authStatus.migrated && isAzureAdB2CEnabled,
      };
    },

    async autoLogin(sampleGuid: string): Promise<void> {
      const res = await autoLogin(sampleGuid);
      if (res.status === AutoLoginStatus.Succeeded && res.token) {
        await this.setToken(res.token);
      }
    },

    async signout(logOutOnAllSquares = true, invalidToken = false, noRedirect = false): Promise<void> {
      removeCacheValue('adid');
      const squareStore = useSquareStore();
      if (this.token && !this.tokenInfo?.isMobileAppUser) {
        try {
          removeUserFromGroup(squareStore.info.guid);
        } catch {
          // sometimes, when redirecting, signalr connection is lost faster than `removeUserFromGroup` can happen
        }
        if (logOutOnAllSquares && this.tokenInfo?.role !== Role.Participant) {
          await logoutOnAllSquares();
        }
      } else {
        // we may not have a token in the store, but the AD login may have failed,
        // so, if AD login is enabled, perform its logout
        if (invalidToken && squareStore.info.adLogin) {
          logoutFromAD();
          return;
        }
      }

      removeCacheValue(JWT_KEY);
      await this.setImpersonate();
      this.logoutFunctions.forEach((fn) => fn());

      if (this.isAdUser) {
        // logout from AD too
        logoutFromAD();
        return;
      }

      // if noRedirect, we do not need to change the route
      if (noRedirect) {
        return;
      }

      // TODO: In the Angular implementation we removed the platform notice here?
      // It should be moved to the platformnotice service that calls the addLogoutFuncton method once that service has been migrated.

      // unsubscribe route needs to be excluded, because you should be redirected to the reactivate page and not the login page
      if (router.currentRoute.value.name !== RouteNames.Unsubscribe
        && router.currentRoute.value.name !== RouteNames.Login) {
        // We use the location object instead of the vue router to make sure that the page reloads and the vuex stores get cleared
        const href = router.resolve({ name: RouteNames.Login }).href;
        location.assign(location.origin + href);
      }
    },

    async postLoginAction(activateWarning: boolean) {
      const squareStatus = useSquareStore().info.status;
      const userRole = this.tokenInfo?.role;

      await hideCaptcha();
      if (squareStatus !== SquareStatus.Archived &&
        (userRole && [Role.Human8, Role.ProfessionalAdmin, Role.ClientAdmin, Role.ClientEditor, Role.Observer].includes(userRole))) {
        const redirectUrl = getSquareAdminUrlForModeratorAndObserver(false, activateWarning);
        window.location.href = redirectUrl;
      } else {
        const redirectUrl = router.currentRoute.value.query.from as string;
        const homeUrl = router.resolve({ name: RouteNames.Home }).path;
        router.push(redirectUrl || homeUrl);
      }
    },

    async login(
      email: string, password: string, captchaResponse: string | undefined = undefined,
      postActions: boolean = true): Promise<boolean> {
      const authResult = await this.authenticate({ username: email, password, captchaResponse });
      const result = authResult.success;

      if (result && postActions) {
        this.postLoginAction(authResult.activateWarning);
      }
      return result;
    },

    async loginAfterRegistration(token: string) {
      await this.setToken(token);
      await this.postLoginAction(false);
    },
  },

  getters: {
    isAuthorized: (state) => hasSsoToken() ? false : state.token != null,
    userRoleLabel: (state) => state.tokenInfo?.role ? Role[state.tokenInfo.role] : '',
    userRole: (state) => state.tokenInfo?.role,
    isAdUser: (state) => state.tokenInfo?.adObjectId ? true : state.adObjectId !== null && state.adObjectId !== '',
    isMobileApp: (state) => state.tokenInfo?.isMobileAppUser,
    isIosDeviceType: (state) => state.tokenInfo?.isMobileAppUser && state.tokenInfo?.mobileDeviceType?.toLowerCase() === 'ios',
    isVersionedMobileAppLogin: (state) => state.tokenInfo?.isMobileAppUser && !!state.tokenInfo.mobileAppVersion,
    isAdmin: (state) => state.tokenInfo?.role === Role.Human8 || state.tokenInfo?.role === Role.ProfessionalAdmin
    || state.tokenInfo?.role === Role.ClientAdmin || state.tokenInfo?.role === Role.ClientEditor,
  },
});

// Helper functions
const getUsernameForParticipant = async (squareParticipantGuid: string) => {
  const detail = (await squareParticipantDetails(squareParticipantGuid)).detail;
  return detail?.username ?? '';
};

const logoutFromAD = () => {
  // If we are unsubscribing with AD we don't want to redirect to the verify it's you or signin
  // we want to show the page where he can add a reason for unsubscribing
  const isUnsubscribing = location.href.indexOf('unsubscribe') !== -1;
  const url = new URL(AUTHENTICATION_BASE);
  url.pathname = 'logout';
  url.searchParams.append('return_uri', isUnsubscribing ? encodeURIComponent(location.href) : encodeURIComponent(location.origin));
  location.assign(url.toString());
};
