import { ClientVideoStorage, SquareStatus } from '@/common/constants/enums';
import { INSITES_URL } from '@/common/env.config';
import { SquareDesignDetailItemResponse, SquareInfoDetailItem } from '@api/models/query';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { NIL as emptyGuid } from 'uuid';

interface State {
  info: SquareInfoDetailItem & { videoStorage: ClientVideoStorage };
  design: SquareDesignDetailItemResponse;
}

export const useSquareStore = defineStore('square', {
  state: (): State => ({
    info: {
      guid: emptyGuid,
      id: -1,
      adLogin: false,
      adMigration: false,
      adFlowSuffix: '',
      adMigrationForced: false,
      squareName: '',
      subdomain: '',
      language: '',
      status: SquareStatus.Draft,
      clientGuid: '',
      clientName: '',
      programGuid: '',
      ssoEnabled: false,
      isCompleted: false,
      ssoExternalLoginUrl: '',
      dateLastImageChange: undefined as DateTime | undefined,
      anonymous: false,
      isPublicProfileEnabled: false,
      countries: [],
      videoStorage: ClientVideoStorage.Mux,
      jobIds: '',
      shouldShowAppStoreLinksOnMobileWeb: false,
      isOpenRegistration: false,
    },
    design: {
      squareGuid: '',
      customCss: '',
      pageDesign: [],
      squareDesign: {
        backgroundColor: '',
        baseColor1: '',
        guid: '',
        hasBackground: false,
        hasCustomCss: false,
        hasHeaderDesktop: false,
        hasHeaderEmail: false,
        hasHeaderLogo: false,
        hasHeaderMobile: false,
        hasLogoTopBar: false,
        hasActivationEmailImage: false,
        hasActivationReminderEmailImage: false,
        hasAlmostInactiveEmailImage: false,
        hasChangeEmailImage: false,
        hasDigestEmailImage: false,
        hasDownloadVoucherEmailImage: false,
        hasFailedStimuliEmailImage: false,
        hasForgotPasswordEmailImage: false,
        hasReplyAcceptedEmailImage: false,
        hasForgotPasswordMultipleSquaresEmailImage: false,
        hasIncentiveEmailImage: false,
        hasNewsletterEmailImage: false,
        hasProbingQuestionsEmailImage: false,
        hasReactivationEmailImage: false,
        hasReplyWithAnswerRequiredEmailImage: false,
        hasResearchActivityIncompleteEmailImage: false,
        hasResearchActivityInviteEmailImage: false,
        hasResearchActivityReminderEmailImage: false,
        hasSavingUpRewardsCorrectionEmailImage: false,
        hasSquareClosedPointsExpirationEmailImage: false,
        hasWelcomeEmailImage: false,
        hasCommunicationCodeEmailImage: false,
        id: -1,
        navigationBarLeft: '',
        navigationBarRight: '',
        navigationBarText: '',
        squareGuid: '',
        hasCustomEmailCss: false,
      },
    },
  }),

  actions: {
    setSquareInfo(squareInfo: State['info']) {
      this.info = squareInfo;
    },
    setSquareDesign(squareDesign: State['design']) {
      this.design = squareDesign;
    },
  },

  getters: {
    isActive: (state) => state.info.status === SquareStatus.Active,
    adminUrl: (state) =>
      new URL(`/client/${state.info.clientGuid}/program/${state.info.programGuid}/square/${state.info.guid}`, INSITES_URL).toString(),
    clientGuid: (state) => state.info.clientGuid,
    squareGuid: (state) => state.info.guid,
  },
});
