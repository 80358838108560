import { DateTime } from 'luxon';

export interface StoreLinksResponse {
  appName?: string
  playStoreLink?: string
  appStoreLink?: string
}

export interface GetAccessForActivityResponse {
  squareParticipantGuid: string
  email?: string
  hasSpecificAccess: boolean
}

export interface HaveUsersAccessToActivityResponse {
  squareParticipantGuid: string
  squareParticipantRole: Role
}

export enum Role {
 NoRoleYet = 0,
 Human8 = 1,
 Observer = 2,
 Participant = 3,
 ClientApi = 4,
 ServiceApi = 5,
 SsoUser = 6,
 ProfessionalAdmin = 7,
 AzureDevOps = 8,
 ClientAdmin = 9,
 ClientEditor = 10,
 All = 1000,
}

export interface HaveUsersAccessToActivityRequest {
  activityGuid: string
  squareParticipantGuids?: string[]
}

export interface HasUserAccessToActivitiesRequest {
  squareParticipantGuid: string
  activityGuids?: string[]
}

export interface ValidateSequencedActivityResponse {
  activitySequenceWarning: ActivitySequenceWarnings
  activitySequenceError: ActivitySequenceErrors
  parentStartDate?: DateTime
  parentEndDate?: DateTime
  parentDateOffset?: number
}

export enum ActivitySequenceWarnings {
 None = 0,
 StartDate = 1,
 EndDate = 2,
 Targeting = 4,
 Theme = 8,
 ChildHasEmailCommunication = 16,
 ParentHasEmailCommunication = 32,
 ChildHasExecutionDateBeforeParentEndDate = 64,
 CompleteRedirect = 128,
 CardVisibility = 256,
 CompletionTarget = 512,
}

export enum ActivitySequenceErrors {
 None = 0,
 PeriodicReward = 1,
}

export interface ActivitySequenceListItemResponse {
  parentGuid: string
  childGuid: string
  childOrder: number
  squareGuid: string
}

export interface NextSequencedActivityRedirectInfoResponse {
  completedActivityName?: string
  completedActivityOrder: number
  nextActivityName?: string
  nextActivityGuid?: string
  nextActivityOrder: number
  totalActivitiesInSequence: number
}

export interface ListOrphanItemResponse {
  list?: OrphanListItemResponse[]
}

export interface OrphanListItemResponse {
  id: number
  guid: string
  name?: string
  programGuid: string
  networkGuid?: string
  status: number
  jobIds?: string[]
}

export interface ActivityDetailItemResponse {
  guid: string
  name?: string
  type: SquareActivityType
  format: number
  language?: string
  description?: string
  activityUri?: string
  startDate: DateTime
  endDate?: DateTime
  isCompleted: boolean
  status: SquareActivityStatus
  isPublished: boolean
  surveyPreparationStatus: SurveyPreparationStatus
  piiSensitive: boolean
  complexity: number
  dividedDiscussionTypesEnabled: boolean
  scoutDiscussionTypesEnabled: boolean
  availableTypes?: SquareActivityType[]
  activitySequenceRole: ActivitySequenceRole
  squareJobId?: string
  isAppScreener?: boolean
  creditToClient: boolean
  hasMedia?: boolean
}

export enum SquareActivityType {
 None = 0,
 ConfirmitSurvey = 1,
 QualitativeResearch = 2,
 CheckMarketSurvey = 3,
 PublicQualitativeResearch = 4,
 PrivateQualitativeResearch = 5,
 DecipherSurvey = 6,
 PublicScoutResearch = 7,
 PrivateScoutResearch = 8,
 Discussion20Research = 9,
 DecipherDiarySurvey = 11,
 Observation = 12,
}

export enum SquareActivityStatus {
 Draft = 0,
 Scheduled = 1,
 Active = 2,
 Closed = 3,
 Archived = 4,
}

export enum SurveyPreparationStatus {
 NotStarted = 0,
 Preparing = 1,
 Finished = 2,
}

export enum ActivitySequenceRole {
 Unsequenced = 0,
 Parent = 1,
 Child = 2,
}

export interface ActivityResearchForMessageDetailItemResponse {
  detail?: ActivityResearchForMessageDetailItem
  raffleDetailItems?: RaffleRewardDetailItem[]
}

export interface ActivityResearchForMessageDetailItem extends QueryModelBase {
  name?: string
  surveyId?: string
  inlineSurveyUri?: string
  credits: number
  startDate?: DateTime
  offset?: number
  endDate?: DateTime
  squareGuid: string
  type: SquareActivityType
}

export interface QueryModelBase {
  id: number
  guid: string
}

export interface RaffleRewardDetailItem extends QueryModelBase {
  activityGuid?: string
  amount?: number
  value: number
  currencyGuid: string
  currencyId: number
  description?: string
  isAutomaticApproved: boolean
  segmentGuid?: string
  keySegmentation?: string
  keySegment?: string
  currencyName?: string
  type: RaffleType
}

export enum RaffleType {
 Instant = 1,
 Periodic = 2,
 Manual = 3,
 SavingUp = 4,
}

export interface ListActivityItemResponse {
  list?: PaginatedResultOfActivityListItemResponse
  matches: number
  matchesFrom: number
  filterDataCounters?: FilterDataCounters
}

export interface PaginatedResultOfActivityListItemResponse {
  currentPage: number
  totalPages: number
  items?: ActivityListItemResponse[]
  totalItems: number
}

export interface ActivityListItemResponse {
  guid: string
  name?: string
  status: SquareActivityStatus
  type: SquareActivityType
  format: SquareActivityFormat
  contributionType: ActivityContributionType
  endDate?: DateTime
  progress?: Progress
  isCompleted: boolean
  childActivities?: ActivityListItemResponse[]
  childOrder: number
  isFiltered: boolean
  themeName?: string
  themeGuid: string
  activityUri?: string
  couldBeParent: boolean
  isParent: boolean
  isDraggable: boolean
  showArchivedButton: boolean
  isArchived: boolean
  segmentationGuid?: string
  offset: number
  moderatorCuration?: boolean
  activityConversationFilterResult?: ActivityFilterResult
  spotsLeft?: number
  totalRewarded?: number
  completionPoints?: number
}

export enum SquareActivityFormat {
 None = 0,
 Screener = 1,
 Profile = 2,
 Research = 3,
}

export enum ActivityContributionType {
 Standard = 0,
 Diary = 1,
}

export interface Progress {
  total?: number
  completed: number
  inProgress: number
  target: number
  percentage?: Percentage
  qualified: number
}

export interface Percentage {
  completed: number
  inProgress: number
  target: number
}

export interface ActivityFilterResult {
  replies: number
  ratings: number
  unRead: number
  matchingConversations: number
  hasStimuli: boolean
}

export interface FilterDataCounters {
  themes?: Record<string, number>
  segments?: Record<string, number>
  members?: Record<string, number>
  tags?: Record<string, number>
  hashtags?: Record<string, number>
  probeQuestions?: Record<string, number>
  annotationsOptions?: Record<string, number>
  stimuliOptions?: Record<string, number>
  ratings?: Record<string, number>
  moderationStatusOptions?: Record<string, number>
  conversationsCount: number
  hasDownloadableVideo: boolean
  hasDownloadableImage: boolean
}

export enum SelectedSegmentsOption {
 None = 0,
 Any = 1,
 All = 2,
}

export enum ConversationSortOption {
 Chronological = 0,
 MostRecent = 1,
 MostLiked = 2,
}

export interface SurveyActivityResponse {
  activityGuid: string
  activityName?: string
  surveyId?: string
  type: SquareActivityType
}

export interface ScreenerSurveysForOpenRegistrationResponse {
  activityGuid: string
  activityName?: string
  isOpenRegistrationScreener?: boolean
  actStatus: SquareActivityStatus
}

export interface ProgramActivitiesResponse {
  list?: ProgramActivityListItem[]
}

export interface ProgramActivityListItem extends QueryModelBase {
  name?: string
  format: SquareActivityFormat
  squareId: number
  programGuid: string
  isCompleted: boolean
  activityType: SquareActivityType
  status: SquareActivityStatus
}

export interface ActivitySegmentationSelectionResponse {
  list?: SegmentationSelectionItem[]
}

export interface SegmentationSelectionItem extends QueryModelBase {
  name?: string
  squareGuid: string
  isStarred: boolean
  segments?: SegmentSelectionItem[]
  surveyQuestions?: SurveyQuestionListItem[]
}

export interface SegmentSelectionItem extends QueryModelBase {
  answer?: string
  surveyAnswers?: SurveyAnswerListItem[]
  segmentationGuid: string
  hasAnswer: boolean
  precode?: string
  memberCount: number
}

export interface SurveyAnswerListItem extends SurveyModelBase {
  precode?: string
  surveyQuestionGuid: string
}

export interface SurveyModelBase extends QueryModelBase {
  label?: string
}

export interface SurveyQuestionListItem extends QueryModelBase {
  question?: string
  name?: string
  isRemovedFromSurvey: boolean
  activityGuid?: string
  activityName?: string
  segmentationSelections?: SegmentationSelectionItem[]
}

export interface ActivityQuantResearchDetailItemResponse {
  detail?: ActivityResearchDetailItem
  raffleDetailItems?: RaffleRewardDetailItem[]
  activityQuantDetail?: ActivityQuantDetailItem
  communicationStimuli?: ActivityQuantCommunicationStimuliListItem
}

export interface ActivityResearchDetailItem extends QueryModelBase {
  surveyId?: string
  inlineSurveyUri?: string
  activityName?: string
  startDate?: DateTime
  offset?: number
  endDate?: DateTime
  credits: number
  completionPoints?: number
  completionPointsQuotaFull?: number
  completionPointsScreenedOut?: number
  squareNames?: string
  format: SquareActivityFormat
  status: SquareActivityStatus
  raffleExecutionDate?: DateTime
  dateLastSynced?: DateTime
  themeGuid?: string
  activityCompleteRedirectGuid?: string
  isCompletionTarget: boolean
  completionTarget?: number
  sequenceRole: ActivitySequenceRole
  activityType: SquareActivityType
  hasTargeting: boolean
  squareJobId?: string
  isAppScreener?: boolean
  isPublished: boolean
  canChangeStartDate: boolean
}

export interface ActivityQuantDetailItem extends QueryModelBase {
  activityGuid: string
  title?: string
  description?: string
  cardTeaser?: string
  callToActionText?: string
  sticky?: boolean
  showOnHomepage: boolean
  communicationGuid?: string
  activityType: SquareActivityType
}

export interface ActivityQuantCommunicationStimuliListItem extends QueryModelBase {
  fileType?: string
  type: StimuliType
  url?: string
  thumbnailUrl?: string
  activityGuid: string
}

export enum StimuliType {
 Card = 0,
 Newsletter = 1,
 Email = 2,
 Forums = 3,
 ChallengeUpdate = 4,
 DefaultMessage = 5,
 News = 6,
}

export interface ActivityQualResearchDetailItemResponse {
  detail?: ActivityResearchDetailItem
  activityQualDetail?: ActivityQualDetailItem
  conversationStimuli?: ConversationStimuliListItem[]
  conversationAttachment?: ConversationAttachmentListItem[]
  communicationStimuli?: ActivityQualCommunicationStimuliListItem
  completeCriterias?: ActivityQualCompleteCriteriaListItem[]
  probeQuestions?: ConversationListItem[]
  probeQuestionsAlreadyAnswered: boolean
}

export interface ActivityQualDetailItem extends QueryModelBase {
  activityGuid: string
  context: ActivityContextType
  visibility: ActivityVisibilityType
  title?: string
  description?: string
  prePopulateDescription?: string
  caption?: string
  cardTeaser?: string
  callToActionText?: string
  sticky?: boolean
  conversationGuid?: string
  openPostUsername?: string
  communicationGuid?: string
  isPublished: boolean
  showOnHomepage?: boolean
  showOnPublicPage?: boolean
  isOneByOne: boolean
  conversationFocusType: ConversationFocusType
  contributionType?: ActivityContributionType
  moderatorCuration: boolean
  visibilityConditionType?: ActivityVisibilityConditionType
  visibilityConditionBufferHours?: number
  visibilityConditionDate?: DateTime
}

export enum ActivityContextType {
 Public = 0,
 Private = 1,
}

export enum ActivityVisibilityType {
 Influenced = 0,
 Uninfluenced = 1,
 Private = 2,
}

export enum ConversationFocusType {
 Text = 1,
 Visual = 2,
}

export enum ActivityVisibilityConditionType {
 Default = 0,
 AcceptedContribution = 1,
}

export interface ConversationStimuliListItem extends QueryModelBase {
  type: ConversationStimuliType
  storage: ClientVideoStorage
  value?: string
  url?: string
  thumbnailUrl?: string
  conversationGuid: string
  sortOrder: number
  isBroken: boolean
  dateCreated?: DateTime
  dateModified?: DateTime
}

export enum ConversationStimuliType {
 Image = 0,
 Video = 1,
 Youtube = 2,
 Vimeo = 3,
}

export enum ClientVideoStorage {
 AzureMediaServices = 0,
 Mux = 1,
 Human8MediaService = 2,
}

export interface ConversationAttachmentListItem extends QueryModelBase {
  fileType?: string
  url?: string
  name?: string
  conversationGuid: string
  sortOrder: number
}

export interface ActivityQualCommunicationStimuliListItem extends QueryModelBase {
  fileType?: string
  type: StimuliType
  url?: string
  thumbnailUrl?: string
  activityQualGuid: string
}

export interface ActivityQualCompleteCriteriaListItem extends QueryModelBase {
  activityQualGuid: string
  criteriaType: ActivityQualCompleteCriteriaType
  value: number
}

export enum ActivityQualCompleteCriteriaType {
 Word = 1,
 Image = 2,
 Video = 3,
 OnlineMedia = 4,
 Attachment = 5,
}

export interface ConversationListItem extends QueryModelBase {
  topic: number
  title?: string
  message?: string
  caption?: string
  dateCreated: DateTime
  datePosted?: DateTime
  squareParticipantGuid: string
  squareParticipantId: number
  squareParticipantRole: Role
  username?: string
  daysSinceUnsubscribed: number
  rating: number
  hasVideo: boolean
  hasImage: boolean
  activityGuid: string
  activityType: SquareActivityType
  parentMessageGuid?: string
  isOpeningPost: boolean
  set?: number
  questionType?: ConversationQuestionType
  type: ConversationElementType
  level: number
  parentMessage?: string
  answerRequired: boolean
  isAccepted: boolean
}

export enum ConversationQuestionType {
 None = 0,
 Text = 1,
 Image = 2,
 Video = 3,
 ImageAndVideo = 4,
 Attachment = 5,
 Freestyle = 6,
}

export enum ConversationElementType {
 None = 0,
 Topic = 1,
 Question = 2,
 Answer = 3,
 Reply = 4,
 IndividualUpdatePost = 5,
}

export interface ListProfileActivityDetailItemResponse {
  list?: ProfileActivityDetailItemResponse[]
}

export interface ProfileActivityDetailItemResponse {
  guid: string
  name?: string
  completedPercentage: number
  sampleStatus: SampleStatus
  credits: number
  completionPoints?: number
  type: SquareActivityType
  squareParticipantGuid: string
}

export enum SampleStatus {
 Inserted = 0,
 ToDo = 2,
 InProgress = 3,
 Completed = 4,
}

export interface GetTestActivityDetailsResponse {
  guid: string
  name?: string
  type?: SquareActivityType
}

export interface ListRecruitmentPartnersResponse {
  partners?: ActivityRecruitmentPartnerListItem[]
}

export interface ActivityRecruitmentPartnerListItem extends QueryModelBase {
  activityGuid: string
  partnerName?: string
  partnerId: number
  linkCount: number
  callbackType: RecruitmentPartnerCallbackType
  linkType: RecruitmentPartnerLinkType
  isMobileApp: boolean
  isOpenRegistration: boolean
  consents?: ActivityRecruitmentPartnerConsentListItem[]
  useSubsource: boolean
}

export enum RecruitmentPartnerCallbackType {
 Pixel = 0,
 Redirect = 1,
}

export enum RecruitmentPartnerLinkType {
 Closed = 0,
 Open = 1,
}

export interface ActivityRecruitmentPartnerConsentListItem extends QueryModelBase {
  activityRecruitmentPartnerGuid: string
  consent: Consent
  validityExpirationDate?: DateTime
  isGiven: boolean
}

export enum Consent {
 AsynchronousResearch = 0,
}

export interface GetRecruitmentPartnerDetailResponse {
  guid: string
  partnerName?: string
  partnerId: number
  activityGuid: string
  linkType: RecruitmentPartnerLinkType
  callbackType: RecruitmentPartnerCallbackType
  completeUrl?: string
  screenedUrl?: string
  quotaUrl?: string
  createdVariableCount: number
  variables?: BackgroundVariableDetailItemResponse[]
  isMobileApp: boolean
  consents?: ActivityRecruitmentPartnerConsentListItem[]
  useSubsource: boolean
}

export interface BackgroundVariableDetailItemResponse {
  guid: string
  name?: string
  label?: string
  values?: BackgroundVariableValueDetailItemResponse[]
  isSynced: boolean
}

export interface BackgroundVariableValueDetailItemResponse {
  guid: string
  value?: string
  label?: string
}

export interface ListActivityPartnerResponse {
  list?: PartnerDto[]
}

export interface PartnerDto {
  id: number
  guid: string
  name?: string
  isMobileApp: boolean
  activityRecruitmentPartnerId: number
}

export interface ActivityReportDataResponse {
  startDate: DateTime
  endDate: DateTime
  startDateOffset: number
  endDateOffset: number
  status: number
  type: number
  format: number
  overallProgress?: OverallProgress
  dropOutRate?: DropOutRate
  reportDataCommunicationList?: ReportDataCommunication[]
  randomlySelected?: number
  excludeParticipantsInvitedSinceWeeks?: number
  targeted: number
  targetedFrom: number
  targetingHistory?: ReportDataTargetingHistory[]
  dateLastSynced?: DateTime
  completionTarget?: number
}

export interface OverallProgress {
  completed: number
  incomplete: number
  invited?: number
  qualified?: number
  screened?: number
  quotaFull?: number
  notStarted?: number
}

export interface DropOutRate {
  incomplete: number
  completePlusIncomplete: number
  percentage: number
}

export interface ReportDataCommunication {
  communicationTitle?: string
  targetingFilterOption: TargetingFilterOption
  items?: ReportDataItem[]
  randomlySelected?: number
  targeted: number
  targetedFrom: number
  communicationChannelTypes?: ChannelType[]
}

export enum TargetingFilterOption {
 None = 1,
 Any = 2,
 All = 3,
}

export interface ReportDataItem {
  segmentationGuid: string
  segmentation?: string
  segments?: string[]
  targetingFilterOption: TargetingFilterOption
  targeted: number
}

export enum ChannelType {
 None = 0,
 CommunicationCard = 1,
 EmailNewsletter = 2,
 CommunicationText = 4,
 ResearchActivityChallenge = 5,
 ResearchActivityEmail = 6,
 ResearchActivityReminder = 7,
 ResearchActivityIncomplete = 8,
 ResearchActivityChallengeHome = 9,
 IncentiveCard = 10,
 ResearchActivityMobileApp = 11,
 CommunicationNews = 12,
 Notification = 13,
 NotificationUpdate = 14,
 CommunicationNewsHome = 15,
 DiscussionThread = 16,
 PageIntro = 17,
 CustomAppNotification = 18,
 DigestEmail = 19,
 PageCta = 20,
 ProfilePage = 21,
}

export interface ReportDataTargetingHistory {
  communicationDateCreated?: DateTime
  targetingFilterOption: TargetingFilterOption
  items?: ReportDataItem[]
  targeted: number
  targetedFrom: number
  randomlySelected?: number
  excludeParticipantsInvitedSinceWeeks?: number
  targetingType: TargetingFilterType
  targetingQuota?: TargetingQuota
}

export enum TargetingFilterType {
 None = 0,
 BySegmentations = 1,
 ByMemberIds = 2,
 All = 3,
}

export interface TargetingQuota {
  totalRandom: number
  targetingQuotaItems?: TargetingQuotaItem[]
  segmentationGuids?: string[]
  dateLastFeasibilityCheck?: DateTime
}

export interface TargetingQuotaItem {
  segmentGuids?: string[]
  segmentName?: string
  targetingQuotaItemRows?: TargetingQuotaItemRow[]
}

export interface TargetingQuotaItemRow {
  value: boolean
  amountInTarget: number
  amountAvailable: number
  amountRandom: number
}

export interface CheckActivityNameIsDuplicateResponse {
  isDuplicate: boolean
}

export interface IntakeCallbackResponse {
  redirectUrl?: string
  outComeCode: SurveyOutcomeCode
  ssoToken?: string
  identity?: string
}

export enum SurveyOutcomeCode {
 None = 0,
 Qualified = 1,
 Screened = 2,
 QuotaFull = 3,
}

export interface GetMemberListResponse {
  memberDetails?: MemberDetail[]
}

export interface MemberDetail {
  guid: string
  id: number
  username?: string
}

export interface ActivityCompleteRedirectResponse {
  activityGuid?: string
}

export interface ActivityDateFilterRange {
  startDate?: DateTime
  endDate?: DateTime
}

export interface GetActivityCountByTypeResponse {
  surveyActivityCount: number
  discussionActivityCount: number
}

export interface DecipherConfigurationResponse {
  xmlFound: boolean
  surveyNodeFound: boolean
  loadDataIsConfigured: boolean
  alwaysSaveDataConfigured: boolean
  uniqueIdentifierInExtraVariables: boolean
  customJsConfigured: boolean
  delphiConfigured: boolean
}

export interface GetRespondentRequest {
  sampleGuid: string
  isTest: boolean
  format: SquareActivityFormat
}

export interface ActivitySquareParticipantListItem extends QueryModelBase {
  activityGuid: string
  squareParticipantRole: Role
  username?: string
}

export interface AutoLoginResponse {
  status: AutoLoginStatus
  token?: string
}

export enum AutoLoginStatus {
 NotNeeded = 0,
 Failed = 1,
 Succeeded = 2,
}

export interface TokenForSquareResponse {
  token?: string
  active: boolean
  identity?: string
}

export interface GetTokenInfoResponse {
  role: Role
  squareParticipantId: number
  squareParticipantGuid: string
  mobileAppVersion?: string
  isMobileAppUser: boolean
  mobileDeviceType?: string
  squareGuid: string
  programGuid: string
  clientGuid: string
  uniqueName?: string
  firstName?: string
  familyName?: string
  isTeamLead: boolean
  isDeveloper: boolean
  isSquareOnly: boolean
  hasFullAccess: boolean
  hasUserDoItYourselfAccess: boolean
  isFullDev: boolean
  adObjectId?: string
  username?: string
}

export interface ShouldShowCaptchaResponse {
  showCaptcha: boolean
}

export interface StatusForAuthorizationResponse {
  valid: boolean
  migrated: boolean
  defaultLogin: boolean
}

export interface SelectionUserClientResponse {
  list?: UserClientDto[]
}

export interface UserClientDto {
  id: number
  guid: string
  name?: string
  adminName?: string
  clientCode?: string
  videoStorage: ClientVideoStorage
  lastSelectedDate?: DateTime
}

export interface RelatedClientDto extends ClientBaseDto {
  isJobIdUsed: boolean
  jobIds?: string[]
}

export interface ClientBaseDto {
  id: number
  guid: string
  name?: string
  adminName?: string
  code?: string
}

export interface ClientDetailItemResponse {
  detail?: ClientDetailDto
}

export interface ClientDetailDto extends ClientBaseDto {
  isPinned: boolean
  videoStorage: ClientVideoStorage
  pinnedClientUserId?: number
  relatedClients?: RelatedClientDto[]
}

export interface ClientConfigWelcomeMessageDetailItemResponse {
  title?: string
  message?: string
}

export interface ClientConfigVideoStorageDetailItemResponse {
  videoStorage: ClientVideoStorage
  videoQuality: ClientVideoQuality
  videoPostJob: boolean
}

export enum ClientVideoQuality {
 Low = 0,
 Medium = 1,
 High = 2,
}

export interface ClientConfigColorResponse {
  color?: string
}

export interface ClientConfigurationDetailItemResponse {
  clientCode?: string
  clientGuid: string
  title?: string
  message?: string
  color?: string
  videoStorage: ClientVideoStorage
  dateModified?: DateTime
}

export interface ListActivityJobIdResponse {
  list?: string[]
}

export interface PaginatedResultOfCommunicationChannelListItem {
  currentPage: number
  totalPages: number
  items?: CommunicationChannelListItem[]
  totalItems: number
}

export interface CommunicationChannelListItem extends QueryModelBase {
  channelType: ChannelType
  messageType: MessageType
  title?: string
  communicationGuid: string
  publishOption?: PublishResearchActivityOption
  startDateTime?: DateTime
  startDateOffset?: number
  endDateTime?: DateTime
  endDateOffset?: number
  activityEndDateTime?: DateTime
  activityName?: string
  activityGuid?: string
  squareGuid: string
  sendEmailDelay?: number
  stickyDate?: DateTime
  isPublished: boolean
  markedAsDeleted: boolean
  status: CommunicationStatus
  cardType: CardType
  isVisible: boolean
  activityStatus?: SquareActivityStatus
  isActivityCompletionTargetReached: boolean
  generalType: GeneralCommunicationType
  randomlySelected?: number
  targeted: number
  targetedFrom: number
  dateCreated: DateTime
  hasReminder: boolean
  hasIncomplete: boolean
  targetingComputedString?: string
  showOnHomepage?: boolean
  isRelatedToChildActivity: boolean
  childCount: number
  randomlySelectedFromActivity?: number
  matches?: number
}

export enum MessageType {
 Engagement = 0,
 ResearchActivity = 1,
 QualActivity = 2,
 ProfileActivity = 3,
}

export enum PublishResearchActivityOption {
 HardLaunch = 1,
 SoftLaunch = 2,
}

export enum CommunicationStatus {
 Draft = 0,
 Scheduled = 1,
 Active = 2,
 Closed = 3,
}

export enum CardType {
 None = 0,
 ManualRewards = 1,
 AutomaticSavingUp = 2,
 ManualSavingUp = 3,
}

export enum GeneralCommunicationType {
 Other = 0,
 Email = 1,
 HomePage = 2,
 CustomAppNotification = 3,
}

export interface GetCommunicationFooterListResponse {
  footers?: CommunicationFooterListItem[]
}

export interface CommunicationFooterListItem extends QueryModelBase {
  name?: string
  footerText?: string
  squareGuid: string
}

export interface CommunicationDetailItemResponse {
  communication?: CommunicationDetailItem
  channels?: CommunicationChannelWithStimulus[]
  targeting?: CommunicationTargetingDetailItem
}

export interface CommunicationDetailItem extends QueryModelBase {
  activityGuid?: string
  overviewName?: string
  title?: string
  message?: string
  startDateTime?: DateTime
  squareGuid: string
  messageType: MessageType
}

export interface CommunicationChannelWithStimulus {
  channel?: CommunicationChannelDetailItem
  stimulus?: CommunicationChannelStimuliDetailItem
  startDateTime?: DateTime
  startDateOffset?: number
  channelPrefixTemplate?: string
  channelSuffixTemplate?: string
}

export interface CommunicationChannelDetailItem extends QueryModelBase {
  channelType: ChannelType
  title?: string
  callToActionText?: string
  editUrl?: string
  callToActionUrl?: string
  openInNewTab: boolean
  message?: string
  communicationGuid: string
  startDateTime?: DateTime
  startDateOffset?: number
  endDateTime?: DateTime
  endDateOffset?: number
  squareGuid: string
  stickyDate?: DateTime
  sticky: boolean
  isPublished: boolean
  showOnHomepage: boolean
  newsPageGuid?: string
  communicationStatus: CommunicationStatus
  activityGuid?: string
}

export interface CommunicationChannelStimuliDetailItem extends QueryModelBase {
  type: StimuliType
  fileType?: string
  url?: string
  thumbnailUrl?: string
  communicationGuid: string
  communicationChannelGuid: string
}

export interface CommunicationTargetingDetailItem extends QueryModelBase {
  communicationGuid: string
  randomlySelected?: number
  targeted: number
  targetedFrom: number
}

export interface FileConfigurationDto {
  guid: string
  fileType: FileType
  maxFileSizeMb: number
  extensions?: FileExtensionDto[]
}

export enum FileType {
 Image = 1,
 Video = 2,
 Attachment = 3,
}

export interface FileExtensionDto {
  fileConfigurationId: number
  mimeType?: string
  extension?: string
}

export interface ExportDiscussionStimuliResponse extends ExportStimuliResponseBase {
  conversationGuid: string
}

export interface ExportStimuliResponseBase {
  stimuli?: ExportDiscussionStimulus[]
  success: boolean
  exception?: Exception
}

export interface ExportDiscussionStimulus {
  type: number
  storage: number
  assetId?: string
  url?: string
}

export interface Exception {
  message?: string
  innerException?: Exception
  stackTrace?: string
  source?: string
}

export interface ExportDiscussionStimuliRequest {
  activityGuid: string
}

export interface ExportDiscussionActivityResponse {
  posts?: Post[]
  openingPost?: Post
}

export interface Post {
  guid: string
  authorGuid: string
  onBehalfOfGuid?: string
  dateCreated: DateTime
  isOpeningPost: boolean
  level: number
  moderationStatus?: ModerationStatus
  message?: string
  questions?: Question[]
  answers?: Answer[]
  stimuli?: StimulusViewModel[]
  likes: number
  rating: number
  tags?: string[]
  notes?: string[]
  highlights?: HighlightViewModel[]
  hashtags?: string[]
}

export enum ModerationStatus {
 Unmoderated = 0,
 UpdateRequired = 1,
 Accepted = 2,
 Other = 3,
}

export interface Question {
  guid: string
  text?: string
}

export interface Answer {
  guid: string
  questionGuid: string
  text?: string
}

export interface StimulusViewModel {
  guid: string
  type: StimulusType
  sortOrder: number
  uploadId?: string
  thumbnailUrl?: string
  url?: string
  name?: string
  status: StimuliUploadState
  mimeType?: string
}

export enum StimulusType {
 Photo = 0,
 Video = 1,
 Youtube = 2,
 Vimeo = 3,
 Attachment = 4,
}

export enum StimuliUploadState {
 Empty = 0,
 Uploading = 1,
 Encoding = 2,
 Valid = 3,
 Invalid = 4,
 InvalidAndNotified = 5,
 Transcribing = 6,
}

export interface HighlightViewModel extends AnnotationBaseViewModel {
  startIndex: number
  endIndex: number
}

export interface AnnotationBaseViewModel {
  guid: string
  postGuid: string
  answerGuid?: string
  stimulusGuid?: string
  authorGuid: string
}

export interface ExportDiscussionActivityRequest {
  activityGuid: string
  filters?: ConversationFilterBase
  exportOnlyFilteredData: boolean
}

export interface ConversationFilterBase {
  forceReindex?: boolean
  noUnreadConversationsFeatureEnabled?: boolean
  keyword?: string
  startDate?: DateTime
  endDate?: DateTime
  selectedStimuliOptions?: number[]
  selectedAnnotationsOptions?: number[]
  selectedThemes?: string[]
  selectedMembers?: string[]
  selectedSegments?: string[]
  selectedSegmentsOption: SelectedSegmentsOption
  selectedRatings?: number[]
  tagsSelected?: string[]
  hashtagsSelected?: string[]
  statuses?: SquareActivityStatus[]
  selectedModerationStatusOptions?: number[]
  selectedProbeQuestions?: string[]
  sortBy: ConversationSortOption
  isDiscussionNew?: boolean
}

export interface GetHashtagSuggestionsResponse {
  hashtags?: string[]
}

export interface GetMentionSuggestionsResponse {
  usernames?: string[]
}

export interface GetAnnotationsResponse extends GetAnnotationsResponse2 {
  annotations?: DiscussionActivityPostAnnotationViewModel[]
}

export interface DiscussionActivityPostAnnotationViewModel extends PostAnnotationsViewModel {
  notes?: DiscussionActivityNoteViewModel[]
}

export interface DiscussionActivityNoteViewModel extends NoteViewModel {
  authorUsername?: string
}

export interface NoteViewModel extends AnnotationBaseViewModel {
  text?: string
}

export interface PostAnnotationsViewModel {
  postGuid: string
  rating?: RatingViewModel
  likes?: LikeViewModel[]
  notes?: NoteViewModel[]
  tags?: TagViewModel[]
  mentions?: MentionViewModel[]
  hashtags?: HashtagViewModel[]
  highlights?: HighlightViewModel[]
  quotes?: QuoteViewModel[]
}

export interface RatingViewModel extends AnnotationBaseViewModel {
  value: number
}

export interface LikeViewModel extends AnnotationBaseViewModel {
  value: boolean
}

export interface TagViewModel extends AnnotationBaseViewModel {
  text?: string
}

export interface MentionViewModel extends AnnotationBaseViewModel {
  mentionedUserGuid: string
}

export interface HashtagViewModel extends AnnotationBaseViewModel {
  text?: string
}

export interface QuoteViewModel extends AnnotationBaseViewModel {
  startIndex: number
  endIndex: number
}

export interface GetAnnotationsResponse2 {
  annotations?: PostAnnotationsViewModel[]
}

export interface GetHashtagsResponse {
  hashtags?: Hashtag[]
}

export interface Hashtag {
  text?: string
  guid: string
}

export interface GetTagsResponse {
  tags?: Tag[]
}

export interface Tag {
  text?: string
  guid: string
}

export interface GetAllTagsOnSquareResponse {
  tags?: string[]
}

export interface ViewDiscussionActivityResponse {
  discussionActivity?: DiscussionActivityViewModel
}

export interface DiscussionActivityViewModel extends DiscussionViewModel {
  posts?: DiscussionActivityPostViewModel[]
  probingPosts?: DiscussionActivityPostViewModel[]
  activityGuid: string
  activityContributionType: ActivityContributionType
  appearance: Appearance
  showQuestionsOneByOne: boolean
  activityVisibilityType: ActivityVisibilityType
}

export interface DiscussionActivityPostViewModel extends PostViewModel {
  author?: AuthorViewModel
  onBehalfOf?: AuthorViewModel
  replies?: DiscussionActivityPostViewModel[]
}

export interface AuthorViewModel {
  id: number
  guid: string
  username?: string
  avatar?: string
  role: Role
  segmentations?: SegmentationViewModel[]
}

export interface SegmentationViewModel {
  segmentation?: string
  segments?: string
}

export interface PostViewModel {
  guid: string
  isOpeningPost: boolean
  isPublished: boolean
  isAnonymized: boolean
  authorGuid: string
  onBehalfOfGuid?: string
  moderationStatus?: ModerationStatus
  label?: PostLabel
  datePublished?: DateTime
  message?: MessageViewModel
  questions?: QuestionViewModel[]
  answers?: AnswerViewModel[]
  mentions?: string[]
  displayStyle: DisplayStyles
  level: number
  likes: number
  isLiked: boolean
  isInappropriate: boolean
  sortOrder: number
  replies?: PostViewModel[]
  isAnswerable: boolean
  isCollapsed: boolean
  replyCount: number
  hasDraftReplies: boolean
  submissionOrder?: number
  isEdit: boolean
}

export enum PostLabel {
 NoLabel = 0,
 UpdateRequired = 1,
 Updated = 2,
 Accepted = 3,
 Probed = 4,
}

export interface MessageViewModel {
  guid: string
  postGuid: string
  text?: string
  caption?: string
  stimuli?: StimulusViewModel[]
}

export interface QuestionViewModel {
  guid: string
  postGuid: string
  text?: string
  caption?: string
  stimuli?: StimulusViewModel[]
  type: QuestionTypes
  isOptional: boolean
  sortOrder: number
  displayStyle: DisplayStyles
}

export enum QuestionTypes {
 Text = 1,
 Attachment = 2,
 Photo = 4,
 Video = 8,
 PhotoVideo = 12,
 Freestyle = 15,
}

export enum DisplayStyles {
 None = 0,
 GreyedOut = 1,
 Hidden = 2,
 Focused = 4,
 PlaceHolder = 8,
}

export interface AnswerViewModel {
  guid: string
  postGuid: string
  questionGuid: string
  text?: string
  caption?: string
  stimuli?: StimulusViewModel[]
  questionType: QuestionTypes
  sortOrder: number
}

export enum Appearance {
 Forum = 1,
 Chat = 2,
 Titles = 3,
 Centered = 4,
}

export interface DiscussionViewModel {
  discussionGuid: string
  title?: string
  posts?: PostViewModel[]
  probingPosts?: PostViewModel[]
  totalPages: number
  currentPage: number
  hasReplied: boolean
  isOneByOne: boolean
  nextUnansweredQuestionGuid?: string
  expandedPosts?: string[]
}

export enum DiscussionSort {
 Chronological = 0,
 MostRecent = 1,
 MostLiked = 2,
}

export interface SearchDiscussionActivityResponse {
  discussionActivity?: DiscussionActivityViewModel
  filterDataCounters?: FilterDataCounters
}

export interface SearchDiscussionActivityRequest extends SearchDiscussionRequest {
  activityGuid: string
  filters?: ConversationFilterBase
}

export interface SearchDiscussionRequest extends ViewDiscussionRequest {
  postGuids?: string[]
}

export interface ViewDiscussionRequest {
  guid: string
  pageNumber: number
  pageSize: number
  sort: DiscussionSort
  focus?: string
  groupByMember: boolean
  isMmRequest: boolean
  expandedPosts?: string[]
}

export interface GetDiscussionActivityResponse {
  discussionGuid: string
  activityGuid: string
  communicationGuid: string
  title?: string
  contributionType: ActivityContributionType
  visibilityType: ActivityVisibilityType
  moderatorCuration: boolean
  appearance?: Appearance
  isOneByOne: boolean
  isAnswered: boolean
  caption?: string
}

export interface ViewDiscussionActivityForSquareParticipantResponse extends ViewDiscussionForAuthorResponse {
}

export interface ViewDiscussionForAuthorResponse {
  discussionGuid: string
  posts?: Post2[]
}

export interface Post2 {
  onBehalfOf?: string
  postGuid: string
  postText?: string
  stimuli?: Stimulus[]
}

export interface Stimulus {
  guid: string
  type: StimulusType
  sortOrder: number
  url?: string
  thumbnailUrl?: string
  name?: string
}

export interface GetSquareParticipantsResponse {
  authors?: AuthorViewModel[]
}

export interface CheckStimulusUrlsResponse {
  encodingFinished: boolean
  url?: string
  thumbnailUrl?: string
}

export interface GetOpeningPostResponse {
  post?: DiscussionActivityPostViewModel
}

export interface ExportRoomsForHealthResponse {
  success: boolean
  rooms?: ExportRoomForHealthViewModel[]
}

export interface ExportRoomForHealthViewModel {
  guid: string
  postsByAuthor?: Record<string, number>
}

export interface ExportRoomsForHealthRequest {
  roomGuids?: string[]
}

export interface ExportLoungeDiscussionResponse {
  success: boolean
  id: number
  title?: string
  roomGuid: string
  posts?: LoungePost[]
}

export interface LoungePost {
  guid: string
  authorGuid: string
  onBehalfOfGuid?: string
  postDate: DateTime
  level: number
  message?: string
  stimuli?: string[]
  attachments?: string[]
  captions?: string[]
  like: number
  rating: number
  tags?: string
  notes?: string
  highlights?: string
  hashtags?: string
}

export interface ExportLoungeDiscussionRequest {
  discussionTopicGuid: string
  filters?: ConversationFilterBase
}

export interface GetPageRoomsResponse {
  rooms?: RoomViewModel[]
}

export interface RoomViewModel {
  guid: string
  name?: string
  topicCount: number
  replyCount: number
}

export interface GetRoomTopicsResponse {
  topics?: TopicViewModel[]
  currentPage: number
  totalPages: number
}

export interface TopicViewModel {
  title?: string
  guid: string
  likeCount: number
  replyCount: number
  dateCreated: DateTime
  lastActivityDate: DateTime
  author?: SquareParticipantViewModel
}

export interface SquareParticipantViewModel {
  id: number
  guid: string
  username?: string
  avatar?: string
  label?: string
}

export enum RoomTopicsSort {
 MostRecentActivity = 0,
}

export interface GetTopicResponse {
  discussion?: DiscussionViewModel
}

export interface GetMentionSuggestionsResponse2 {
  usernames?: string[]
}

export interface ListExportLogResponse {
  list?: LogMessageExport[]
}

export interface LogMessageExport extends LogMessageBase {
  dateStarted?: DateTime
  dateEnded?: DateTime
  squareGuid?: string
  squareParticipantGuid?: string
  userRole?: string
  exportType?: string
  exportDetails?: string
  exportError?: Exception
  exportFilter?: object
  logType: LogType
}

export enum LogType {
 Log = 0,
 Audit = 1,
 Mail = 2,
 Export = 3,
 ManualReward = 4,
 Upload = 5,
}

export interface LogMessageBase {
  id?: string
  dateCreated: DateTime
  environment?: string
  userId: number
  clientCode?: string
  logType: LogType
}

export enum RoleExternalUser {
 NoRoleYet = 0,
 Confirmit = 1,
 IdeaStream = 2,
 SquareApp = 3,
 SurvMan = 4,
 Decipher = 5,
 Eyeka = 6,
 HealthDashboard = 7,
 PublicApi = 8,
 GeofencingApi = 9,
 Connect = 10,
}

export interface FeatureSettingsResponse {
  isEnabled: boolean
  isMasterSwitchEnabled: boolean
  settings?: Record<string, string>
}

export enum Feature {
 None = 0,
 CheckMarket = 1,
 SavingUpRewards = 2,
 DividedDiscussionTypes = 3,
 Decipher = 4,
 ScoutDiscussionTypes = 5,
 Quota = 6,
 Confirmit = 7,
 CurationFeedbackCircle = 9,
 AnalysisAndReporting = 10,
 Discussion20New = 11,
 ActivityTargetCompletion = 13,
 ConsentMapping = 14,
 SOITargeting = 15,
 NoUnreadConversations = 16,
 AccessData = 18,
 MediaGallery = 19,
 DecipherDiary = 21,
 Captcha = 23,
 Observation = 24,
 ActivityJobIdEverywhere = 25,
 AzureAdB2CLogin = 26,
 FooterRestrictions = 27,
 SyncQualButtonHomepage = 28,
 ChatGPT = 29,
 Pulse = 30,
 CustomDiscussionImport = 41,
}

export interface FeatureSettingsForParticipantInProgramResponse {
  featureEnabledForSquareDictionary?: Record<string, boolean>
}

export interface GetMasterFeaturesResponse {
  items?: MasterFeature[]
}

export interface MasterFeature {
  feature: Feature
  featureName?: string
  isEnabled: boolean
  isEnabledForSpecificSquares: boolean
  isExcludedForSquaresFromAllSquares: boolean
  specificSquares?: SquareMasterFeature[]
  cannotBeDisabled: boolean
}

export interface SquareMasterFeature {
  clientCode?: string
  squareId: number
}

export interface SquareInstantRewardsListResponse {
  list?: SquareInstantRewardsItem[]
}

export interface SquareInstantRewardsItem extends SquareRewardsItemBase {
  activityGuid: string
  type?: string
  format?: string
  jobIds?: string[]
}

export interface SquareRewardsItemBase {
  guid: string
  name?: string
  status?: string
  totalRewardValue?: string[]
  winners: number
  notApprovedWinners: number
  winnerData?: string[]
  raffleExecutionDate?: DateTime
  offset?: number
}

export interface SquareManualRaffleListReponse {
  list?: ManualRaffleListItemResponse[]
}

export interface ManualRaffleListItemResponse {
  raffleType: RaffleType
  name?: string
  raffleGuid: string
  totalRewards?: TotalReward[]
  status: RaffleStatus
  winners: number
  dateCreated?: DateTime
}

export interface TotalReward {
  value: number
  currency?: string
}

export enum RaffleStatus {
 WinnersNotDrawn = 1,
 WinnersDrawn = 2,
 WinnersPartial = 3,
}

export interface SquareManualRewardWinnerListResponse {
  list?: ManualRewardsListResponse[]
}

export interface ManualRewardsListResponse {
  squareParticipantId: number
  guid: string
  email?: string
  username?: string
  rewardValue: number
  rewardCurrency?: string
  winnerStatus: WinnerStatus
  dateNotificationSent?: DateTime
  rewardPartner?: RewardPartnerType
  redeemedRewardResponse?: string
}

export enum WinnerStatus {
 Approved = 1,
 NotApproved = 2,
 Notified = 3,
 DetailsConfirmed = 4,
 OfferedToRedeem = 5,
}

export enum RewardPartnerType {
 None = 0,
 TangoCardNonEur = 1,
 Paypal = 2,
 Cadora = 3,
 TangoCardEurFrance = 4,
 TangoCardEurGermany = 5,
 TangoCardEurItaly = 6,
 TangoCardEurSpain = 7,
 Other = 8,
 Emag = 9,
 Tremendous = 10,
 PanoramaResearch = 11,
 Ovation = 12,
 UWinIwin = 13,
 Guanaitong = 14,
 GiftPay = 15,
 SavingUpRewardsCorrection = 99,
}

export interface SquarePeriodicRewardsListResponse {
  list?: SquarePeriodicRewardsItem[]
}

export interface SquarePeriodicRewardsItem extends SquareRewardsItemBase {
  startDate?: DateTime
  startDateOffset: number
  endDate?: DateTime
  endDateOffset: number
  credits: number
}

export interface ProfileIncentiveResponse {
  currentRaffleStartDate?: DateTime
  currentRaffleEndDate?: DateTime
  totalCredits: number
  items?: IncentiveItem[]
}

export interface IncentiveItem {
  guid: string
  dateTime?: DateTime
  channelType: ChannelType
  title?: string
  message?: string
}

export interface SavingUpRewardPageListItem extends QueryModelBase {
  squareParticipantGuid: string
  sampleStatus: SampleStatus
  removed: SampleRemovedReasons
  sampleDateCreated?: DateTime
  sampleDateFinished?: DateTime
  activityName?: string
  activityTitle?: string
  activityGuid: string
  activityStatus: SquareActivityStatus
  completionPoints: number
  activityEndDate: DateTime
  activityFormat: SquareActivityFormat
  activityType: SquareActivityType
  receivedPoints?: number
  rewardDateCreated?: DateTime
  pageGuid: string
  isCompletionTargetReached: boolean
  completionTargetReachedDate?: DateTime
  outcomeCode: SurveyOutcomeCode
  isActivityFinished: boolean
  isSampleCompleted: boolean
  isRewardActivityCompleted: boolean
  dateRewardActivityCompleted?: DateTime
  isLocked: boolean
  hasNotification: boolean
}

export enum SampleRemovedReasons {
 None = 0,
 Suspended = 1,
 Unsubscribed = 2,
 NotInTarget = 4,
 ActivitySegmentTargetFull = 8,
 ActivityAccess = 16,
}

export interface RewardWinnerDetailItem extends QueryModelBase {
  raffleGuid: string
  activityGuid?: string
  approverSquareParticipantGuid?: string
  squareGuid: string
  username?: string
  squareParticipantId: number
  email?: string
  status: WinnerStatus
  rewardValue: number
  currencyId: number
  currencyName?: string
  rewardPartner?: RewardPartnerType
  segment?: string
  raffleType: RaffleType
  squareParticipantGuid: string
  dateNotificationSent?: DateTime
  redeemedRewardResponse?: string
  savingUpUnits?: number
  savingUpTotalValue?: number
  dateCreated: DateTime
  manualIntervention?: string
  isRedeemable: boolean
  leftover?: number
  customMessage?: string
}

export interface SquareParticipantCreditListItem extends QueryModelBase {
  activityGuid?: string
  activityName?: string
  activityDescription?: string
  squareParticipantGuid: string
  squareParticipantRole: Role
  credits: number
  dateCreated: DateTime
  type: SquareParticipantCreditType
  rewardWinnerGuid?: string
}

export enum SquareParticipantCreditType {
 None = 0,
 Default = 1,
 SavingUp = 2,
 Leftover = 3,
 Invalidate = 4,
}

export interface SquareParticipantTotalCreditsResponse {
  totalCredits: number
}

export interface CheckGetRewardLinkValidityResponse {
  isLinkValid: boolean
  detailsConfirmed: boolean
}

export interface CheckSquareParticipantIsRewardWinnerResponse {
  isRewardWinner: boolean
  rewardPartnerType: RewardPartnerType
}

export interface RewardsInstantDetailsResponse {
  rewardsInstantDetailsList?: RewardsInstantDetails[]
}

export interface RewardsInstantDetails {
  amount: number
  value: number
  currency?: string
  keySegmentation?: string
  keySegment?: string
  isAutomaticApproved: boolean
}

export interface WinnersDetailsResponse {
  winnersDetailsList?: WinnersDetails[]
}

export interface WinnersDetails {
  squareParticipantId: number
  winnerGuid: string
  username?: string
  email?: string
  segment?: string
  rewardValue: number
  rewardPartner?: RewardPartnerType
  currencyName?: string
  status?: string
  redeemedRewardResponse?: string
}

export interface RewardsPeriodicDetailsResponse {
  name?: string
  startDate: DateTime
  endDate: DateTime
  raffleExecutionDate: DateTime
  offset: number
  rewardsPeriodicDetailsList?: RewardsPeriodicDetails[]
}

export interface RewardsPeriodicDetails {
  rewardGuid: string
  amount: number
  value: number
  currencyGuid: string
  isAutomaticApproved: boolean
  keySegmentationGuid?: string
  keySegmentGuid?: string
  keySegmentation?: string
  keySegment?: string
}

export interface RewardsSavingUpDetailsResponse {
  rewards?: SavingUpRewardDetailsListItem[]
}

export interface SavingUpRewardDetailsListItem extends QueryModelBase {
  units: number
  value: number
  currencyId: number
  currencyGuid: string
  currencyName?: string
  countryIso?: string
  country?: string
  squareGuid: string
}

export interface SavingUpRedeemOverviewResponse {
  pointsSavedUp: number
  minimumPointsToRedeem: number
  totalValue: number
  currency?: string
  unitLabel?: string
  isAutomaticHandlingEnabled: boolean
  remainderForMinimum: number
}

export interface GetManuallyHandledRewardsResponse {
  rewards?: PaginatedResultOfManuallyHandledRewardDto
  allItemGuids?: string[]
}

export interface PaginatedResultOfManuallyHandledRewardDto {
  currentPage: number
  totalPages: number
  items?: ManuallyHandledRewardDto[]
  totalItems: number
}

export interface ManuallyHandledRewardDto {
  id: number
  guid: string
  clientName?: string
  squareName?: string
  jobId?: string
  squareParticipantId: number
  fullName?: string
  email?: string
  amount?: number
  currency?: string
  country?: string
  rewardPartner: RewardPartnerType
  handled: boolean
  handledOn?: DateTime
}

export interface ManuallyHandledRewardRequest extends ManuallyHandledRewardFilterRequest {
  pageNumber: number
  limit: number
  order?: string
}

export interface ManuallyHandledRewardFilterRequest {
  keyword?: string
  statuses?: ManuallyHandledRewardStatus[]
  clients?: string[]
  squares?: string[]
  jobIds?: string[]
  squareParticipants?: number[]
  countries?: string[]
  rewardPartners?: RewardPartnerType[]
  list?: string[]
  startDate?: DateTime
  endDate?: DateTime
}

export enum ManuallyHandledRewardStatus {
 NotHandled = 0,
 Handled = 1,
}

export interface OptionsManuallyHandledRewardFiltersDto {
  clients?: Record<string, string>
  jobIds?: string[]
  countries?: string[]
  squares?: string[]
  squareParticipants?: number[]
  rewardPartners?: RewardPartnerType[]
}

export interface GetJobDetailsResponse {
  state?: string
  result?: string
  succeededAt?: string
  reason?: string
  exceptionMessage?: string
}

export interface ListLogResponse {
  list?: LogMessage[]
}

export interface LogMessage extends LogMessageBase {
  memberName?: string
  filePath?: string
  squareGuid: string
  message?: string
  exceptionCode?: string
  exceptionMessage?: string
  stackTrace?: string
  severity: LogSeverity
  category?: string
  machineName?: string
  isMobileAppUser?: boolean
  mobileAppReference?: string
  correlationId: string
  url?: string
  logType: LogType
  ttl?: number
}

export enum LogSeverity {
 Debug = 0,
 Info = 1,
 Warning = 2,
 Error = 3,
 Critical = 4,
}

export interface ListManualRewardsLogResponse {
  list?: LogMessageManualReward[]
}

export interface LogMessageManualReward extends LogMessageBase {
  dateStarted?: DateTime
  dateEnded?: DateTime
  squareGuid?: string
  squareParticipantGuid?: string
  raffleGuid: string
  entries?: LogMessageManualRewardEntry[]
  logType: LogType
}

export interface LogMessageManualRewardEntry {
  squareParticipantGuid: string
  partner?: string
  currency?: string
  value: number
}

export interface ListNetworkItemResponse {
  list?: NetworkListItem[]
}

export interface NetworkListItem extends QueryModelBase {
  name?: string
  countSquares: number
  countActivities: number
  jobIds?: string[]
}

export interface NetworkDetailItemResponse {
  detail?: NetworkDetailItem
}

export interface NetworkDetailItem extends QueryModelBase {
  name?: string
  description?: string
  culture?: string
  jobIds?: string[]
}

export interface NewsListItem extends QueryModelBase {
  channelType: ChannelType
  title?: string
  message?: string
  startDateTime: DateTime
  channelEndDateTime?: DateTime
  messageType: MessageType
  fileType?: string
  stimuliUrl?: string
  stimuliThumbnailUrl?: string
  stimuliGuid?: string
  squareGuid: string
  likesCount: number
  isLiked: boolean
  communicationSampleGuid: string
  squareParticipantGuid: string
  newsPageGuid: string
  stickyDate?: DateTime
  isCardOrderFifo: boolean
  isPublished: boolean
  isVisible: boolean
  cardType: CardType
}

export interface UserNotificationResponse extends UserNotification {
  label?: string
  relatedNotificationGuids?: string[]
  redirectToUrl?: string
  channelType: ChannelType
  isDiscussionNew: boolean
}

export interface UserNotification {
  guid: string
  squareParticipantGuid: string
  targetGuid?: string
  notificationType: NotificationType
  category: NotificationCategory
  notificationStatus: NotificationStatus
  expiryDate: DateTime
  activityGuid?: string
  activityType?: SquareActivityType
  squareGuid?: string
}

export enum NotificationType {
 ProfileActivityAvailable = 0,
 QualResearchActivityAvailable = 1,
 QuantResearchActivityAvailable = 2,
 QualResearchAlmostClosed = 3,
 QuantResearchAlmostClosed = 4,
 DiscussionMention = 5,
 QualResearchActivityUpdate = 6,
 DiscussionReply = 7,
 DiscussionLike = 8,
 ForumMention = 9,
 ForumReply = 10,
 ForumLike = 11,
 PlatformNotification = 12,
 NextActivityInSequenceLaunched = 13,
 DiscussionActivityComplete = 14,
 SavingUpRewardsEarned = 15,
 NewProbingQuestion = 16,
 NewOptionalProbingQuestion = 17,
 SavingUpRewardsCorrectionApplied = 18,
 RewardsAvailableForRedemption = 19,
 DiscussionLikes = 20,
 ForumLikes = 40,
 DiscussionReplyLike = 110,
 DiscussionReplyLikes = 120,
 DiscussionReplies = 200,
 DiscussionRepliesLike = 210,
 DiscussionRepliesLikes = 220,
 ForumReplyLike = 330,
 ForumReplyLikes = 340,
 ForumReplies = 400,
 ForumRepliesLike = 430,
 ForumRepliesLikes = 440,
 DiscussionMentionLike = 1010,
 DiscussionMentionLikes = 1020,
 DiscussionMentionReply = 1100,
 DiscussionMentionReplyLike = 1110,
 DiscussionMentionReplyLikes = 1120,
 DiscussionMentionReplies = 1200,
 DiscussionMentionRepliesLike = 1210,
 DiscussionMentionRepliesLikes = 1220,
 DiscussionMentions = 2000,
 DiscussionMentionsLike = 2010,
 DiscussionMentionsLikes = 2020,
 DiscussionMentionsReply = 2100,
 DiscussionMentionsReplyLike = 2110,
 DiscussionMentionsReplyLikes = 2120,
 DiscussionMentionsReplies = 2200,
 DiscussionMentionsRepliesLike = 2210,
 DiscussionMentionsRepliesLikes = 2220,
 ForumMentionLike = 3030,
 ForumMentionLikes = 3040,
 ForumMentionReply = 3300,
 ForumMentionReplyLike = 3330,
 ForumMentionReplyLikes = 3340,
 ForumMentionReplies = 3400,
 ForumMentionRepliesLike = 3430,
 ForumMentionRepliesLikes = 3440,
 ForumMentions = 4000,
 ForumMentionsLike = 4030,
 ForumMentionsLikes = 4040,
 ForumMentionsReply = 4300,
 ForumMentionsReplyLike = 4330,
 ForumMentionsReplyLikes = 4340,
 ForumMentionsReplies = 4400,
 ForumMentionsRepliesLike = 4430,
 ForumMentionsRepliesLikes = 4440,
 MoreSavingUpRewardsEarned = 6000,
 NewLoungeTopic = 6001,
 DiscussionMentionForAdmin = 7000,
 NewReplyWithAnswerRequired = 7001,
 ReplyAccepted = 7002,
 FailedStimuliUpload = 7003,
 SquareParticipantAlmostInactive = 7004,
}

export enum NotificationCategory {
 None = 0,
 Activity = 1,
 Profile = 2,
 Page = 3,
 Home = 4,
}

export enum NotificationStatus {
 NotRead = 1,
 Read = 2,
}

export enum EmailType {
 Activation = 0,
 ActivationReminderSoi = 1,
 ForgotPassword = 2,
 ChangeEmail = 3,
 Unsubscribe = 4,
 ActivationReminderDoi = 5,
 Welcome = 6,
 ForgotPasswordMultipleSquares = 7,
 ActivationNonParticipant = 8,
 ActivationReminderSoiNonParticipant = 9,
 ActivationReminderDoiNonParticipant = 10,
 ConfirmCustomEmail = 11,
 ActivityInvite = 100,
 ActivityReminder = 101,
 ActivityIncomplete = 102,
 RewardWinnerNotification = 103,
 Newsletter = 104,
 TangocardBalanceInsufficient = 105,
 TangocardBalanceWarning = 106,
 Digest = 107,
 ProbingQuestions = 108,
 SavingUpRewardsCorrectionNotification = 109,
 RewardWinnerDownloadVoucher = 110,
 IncentiveSupport = 111,
 ReplyWithAnswerRequired = 112,
 ReplyAccepted = 113,
 AlmostInactive = 114,
 FailedStimuliUpload = 115,
 IncentiveReminder = 116,
 ActivationVerifyItIsYou = 117,
 ClosingSquare = 200,
 ArchivingSquare = 201,
 DeletingSquare = 202,
 DeletedSquare = 203,
 JobIdAlmostExpired = 300,
 JobIdExpired = 301,
 ProgramCreditsAlmostExpired = 400,
 ProgramCreditsMonthlyReminder = 401,
}

export interface UserNotificationMobile extends UserNotificationResponse {
  url?: string
  notificationTypeString?: string
  activePageGuid?: string
  notificationCategory?: string
}

export interface ListPageItemResponse {
  list?: PageListItem[]
}

export interface PageListItem extends PageConsumerListItem {
  squareGuid: string
  segmentationGuid?: string
  segmentGuid?: string
  isCardOrderFifo: boolean
  rooms?: RoomListItem[]
}

export interface RoomListItem extends QueryModelBase {
  name?: string
  pageGuid: string
  isVisible?: boolean
  conversationsCount?: number
}

export interface PageConsumerListItem extends QueryModelBase {
  name?: string
  intro?: string
  icon?: string
  pageType: PageType
  area: Area
  openInNewTab?: boolean
  customUrl?: string
  editUrl?: string
  isAboutThisProjectPage: boolean
  displayOrder?: number
  showInMobileNavBar: boolean
}

export enum PageType {
 Home = 1,
 Activities = 2,
 Forum = 3,
 Ideabox = 4,
 News = 5,
 MainPageCustom = 6,
 FAQ = 7,
 PlatformTour = 8,
 Contact = 9,
 HelpCenterCustom = 10,
 FooterCustom = 11,
 IncentivesCustom = 12,
 MyIncentive = 13,
 RewardPolicy = 14,
 SavingUpRewardPolicy = 15,
 MyCookieSettings = 16,
 CookiePolicy = 17,
 Profile = 18,
}

export enum Area {
 Undefined = 0,
 MainPages = 1,
 HelpCenter = 2,
 Footer = 3,
 Incentives = 4,
 Splash = 5,
}

export interface ListPageItemConsumerResponse {
  list?: PageConsumerListItem[]
}

export interface SquareAppPagesResponse {
  pages?: Record<string, string>
}

export interface SquareAppPagesv2Response {
  homePage?: AppPage
  activitiesPage?: AppPageWithCounter
  profilePage?: AppPageWithCounter
  morePage?: AppPageCollection
}

export interface AppPage extends AppPageBase {
  targetUrl?: string
}

export interface AppPageBase {
  pageGuid: string
  label?: string
  icon?: string
  color?: string
  pageType: PageType
  displayOrder?: number
}

export interface AppPageWithCounter extends AppPage {
  count: number
}

export interface AppPageCollection extends AppPageBase {
  pageGroups?: PageGroup[]
}

export interface PageGroup {
  label?: string
  pages?: AppPage[]
}

export interface PageDetailItemResponse {
  detail?: PageDetailItem
}

export interface PageDetailItem extends QueryModelBase {
  name?: string
  intro?: string
  icon?: string
  area: Area
  pageType: PageType
  customUrl?: string
  openInNewTab?: boolean
  image?: string
}

export interface ListFaqCategoryResponse {
  list?: FaqCategoryListItem[]
}

export interface FaqCategoryListItem extends QueryModelBase {
  name?: string
  isVisible: boolean
  pageGuid: string
  squareGuid: string
  type: FaqCategoryType
  isLinkedToIncentives?: boolean
}

export enum FaqCategoryType {
 None = 0,
 ManualRewards = 1,
 SavingUpRewards = 2,
 ManualSavingUpRewards = 3,
}

export interface ListFaqQuestionResponse {
  list?: FaqQuestionListItem[]
}

export interface FaqQuestionListItem extends QueryModelBase {
  title?: string
  isVisible: boolean
  answer?: string
  faqCategoryGuid: string
  pageGuid: string
  squareGuid: string
}

export interface FaqPageDetailItemResponse {
  categories?: FaqCategoryListItem[]
  questions?: FaqQuestionListItem[]
}

export interface SquareParticipantsAvailableForCallRequest {
  squareParticipantGuids?: string[]
}

export interface SquareParticipantUrlValidityResponse {
  isUrlValid: boolean
}

export enum SquareParticipantChangeRequestType {
 ForgotPassword = 1,
 ChangeEmail = 2,
 Subscription = 3,
 ConfirmEmail = 4,
}

export interface SquareParticipantRequestedEmailChangeResponse {
  hasValidEmailChangeRequest: boolean
}

export interface GetSquareParticipantDetailsResponse {
  id: number
  guid: string
  username?: string
  role: Role
  avatar?: string
  segmentations?: SegmentationViewModel2[]
}

export interface SegmentationViewModel2 {
  segmentation?: string
  segments?: string
}

export interface GetSquareParticipantDetailsRequest {
  squareParticipantGuids?: string[]
  includeSegmentations: boolean
}

export interface SquarePasswordPolicyItemResponse {
  passwordLength: number
  hasStrongPasswordEnabled: boolean
}

export interface GetAdObjectIdForCurrentUserResponse {
  adObjectId?: string
  email?: string
  migrated: boolean
  assignedSquares?: Record<string, string[]>
}

export interface SquareParticipantDetailItemResponse extends SquareParticipantActivityContributionDetailsResponse {
  detail?: SquareParticipantDetailItem
  suspendAction?: SuspendActionDetailItem
  adStatus?: GetParticipantADStatusResponse
  publicSegmentationsWithSegments?: SegmentationWithSegmentsModel[]
}

export interface SquareParticipantDetailItem extends QueryModelBase {
  squareId: number
  squareGuid: string
  participantId: number
  status: RegistrationStatus
  locked: boolean
  suspended: boolean
  action: SquareParticipantAction
  lastLoginDate?: DateTime
  claimsName?: string
  dateCreated?: DateTime
  dateModified?: DateTime
  dateMigrationModalShown?: DateTime
  email?: string
  adObjectId?: string
  username?: string
  about?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  addressLine1?: string
  addressLine2?: string
  zipPostalCode?: string
  city?: string
  stateRegion?: string
  role: Role
  profileImageUrl?: string
  hasProfileImage: boolean
  dateLastActivationEmail?: DateTime
  country?: string
  countryName?: string
  anonymousSquare: boolean
  isPublicProfileEnabledOnSquare: boolean
  isAlmostInactiveInActivities?: boolean
  communicationEmail?: string
  communicationEmailCode?: string
  isFullDev: boolean
  migrated: boolean
}

export enum RegistrationStatus {
 SingleOptInNoEmail = 0,
 SingleOptIn = 1,
 SingleOptInReminder = 2,
 DoubleOptIn = 3,
 DoubleOptInReminder = 4,
 Active = 5,
 Unsubscribed = 6,
 Anonymized = 7,
}

export enum SquareParticipantAction {
 None = 0,
 ShouldLoginNextTime = 1,
}

export interface SuspendActionDetailItem extends QueryModelBase {
  isSuspend: boolean
  suspendReason: SuspendReason
  targetSquareParticipantId: number
  userCreated?: number
  comment?: string
  dateCreated: DateTime
  dateModified?: DateTime
}

export enum SuspendReason {
 Unknown = 0,
 SuspendedPanelParticipant = 1,
 JobIdRemoval = 2,
 NotActiveInNavison = 3,
 SuspendedLowQuality = 4,
 SuspendedOther = 5,
 BlackListedFraudulent = 6,
 BlacklistedSpam = 7,
 BlacklistedBot = 8,
 BlacklistedOther = 9,
 RightToBeForgotten = 10,
 SourceConsentExpiration = 11,
 SwitchingLanguage = 12,
 NotSuspended = -1,
}

export interface GetParticipantADStatusResponse {
  adObjectId?: string
  accountType: AccountType
  socialLoginType: SocialLoginType
  migratedOnOtherSquares: boolean
  migratedEmail?: string
}

export enum AccountType {
 SquareAccount = 0,
 SocialLogin = 1,
}

export enum SocialLoginType {
 None = 0,
 Facebook = 1,
 Google = 2,
 Apple = 3,
 WeChat = 4,
}

export interface SegmentationWithSegmentsModel {
  segmentation?: string
  segments?: string
}

export interface SquareParticipantActivityContributionDetailsResponse {
  activitiesCompleted?: number
  activitiesSubmissions?: number
  activitiesAcceptedSubmissions?: number
}

export interface SquareParticipantPublicDetailsResponse extends SquareParticipantActivityContributionDetailsResponse {
  squareParticipantGuid: string
  username?: string
  about?: string
  dateModified?: DateTime
  hasProfileImage: boolean
  publicSegmentationsWithSegments?: SegmentationWithSegmentsModel[]
}

export interface SquareParticipantListWithFiltersResponse {
  searchResult?: PaginatedResultOfSquareParticipantDetailItemResponse
  filterDataCounters?: FilterDataCountersResponse
}

export interface PaginatedResultOfSquareParticipantDetailItemResponse {
  currentPage: number
  totalPages: number
  items?: SquareParticipantDetailItemResponse[]
  totalItems: number
}

export interface FilterDataCountersResponse {
  roles?: Record<string, number>
  statuses?: Record<string, number>
  segments?: Record<string, number>
}

export interface SquareParticipantListItemRequest extends SquareParticipantFilterRequest {
  pageNumber: number
  limit: number
  order?: string
}

export interface SquareParticipantFilterRequest {
  list?: string[]
  keyword?: string
  roles?: Role[]
  statuses?: RegistrationStatus[]
  suspended: boolean
  locked: boolean
  segmentGuids?: string[]
  segmentOptions: SelectedSegmentsOption
  startDate?: DateTime
  endDate?: DateTime
  dateModified?: DateTime
  squareGuid: string
  excludeParticipantsInvitedSinceWeeks?: number
}

export interface SquareParticipantGuidListItemRequest extends SquareParticipantFilterRequest {
}

export interface SquareParticipantSegmentationsResponse {
  segmentationSegmentList?: SegmentationSegmentList[]
}

export interface SegmentationSegmentList {
  segmentationId: number
  segmentation?: string
  segment?: string
  isStarred: boolean
}

export interface GetEmailMigrationInfoForAdLoginResponse {
  adSquaresDetails?: AdSquareDetails[]
}

export interface AdSquareDetails {
  squareGuid: string
  adFlowSuffix?: string
  squareParticipantMigrated: boolean
  role: Role
}

export interface GetSegmentGuidsForAuthorsRequest {
  authorGuids?: string[]
}

export interface ListParticipantActivityItemResponse {
  list?: ParticipantActivityListItemResponse[]
  totalReplies: number
  downloadAvailability: ConversationsDownloadAvailabilities
}

export interface ParticipantActivityListItemResponse {
  guid: string
  name?: string
  status: SquareActivityStatus
  type: SquareActivityType
  sampleStatus: SampleStatus
  outcomeCode: SurveyOutcomeCode
  totalReplies: number
  hasStimuli: boolean
  removed: SampleRemovedReasons
}

export enum ConversationsDownloadAvailabilities {
 None = 0,
 Text = 2,
 Stimuli = 4,
}

export interface SquareParticipantRoleResponse {
  squareParticipantGuid: string
  squareParticipantRole: Role
}

export interface GetSquareParticipantCustomEmailRequest {
  squareParticipantGuids?: string[]
}

export interface AzureAdDetailsDto extends AzureAdBasicDetailsDto {
  guid: string
  firstName?: string
  lastName?: string
  claimsName?: string
  email?: string
  isMemberOfDevelopmentTeam: boolean
  navTeamGuid?: string
  navTeamName?: string
  isActive: boolean
}

export interface AzureAdBasicDetailsDto {
  id: number
  accessLevel: AccessLevels
  userId: number
}

export enum AccessLevels {
 None = 0,
 Square = 1,
 Team = 2,
 Lead = 4,
 TeamLead = 6,
 Full = 8,
 Developer = 16,
 FullDev = 24,
}

export interface ConsentExpirationBannerResponse {
  banners?: ConsentExpirationBannerDetailItem[]
}

export interface ConsentExpirationBannerDetailItem extends QueryModelBase {
  squareGuid: string
  activityName?: string
}

export interface GetSquareActivationQuestionsResponse {
  squareGuid: string
  questions?: GetSquareActivationQuestionResponse[]
}

export interface GetSquareActivationQuestionResponse {
  guid: string
  question?: string
  mandatory: boolean
  used: boolean
  type: SquareActivationQuestionType
  values?: string[]
}

export enum SquareActivationQuestionType {
 Text = 0,
 Checkbox = 1,
 MultiCheckbox = 2,
 Radio = 3,
 List = 4,
 ListCheckbox = 5,
}

export interface GetAvailableSquareParticipantEmailsResponse {
  currentEmail?: string
  previousEmail?: string
}

export interface GetMigrationStatusForEmailResponse {
  adObjectId?: string
  migrated: boolean
  migratedEmail?: string
  socialLoginType: SocialLoginType
  role?: Role
  squaresToMigrate?: string[]
}

export interface SquareParticipantBasicItemResponse {
  squareParticipantGuid: string
  status: RegistrationStatus
}

export interface ListPinnedItemsResponse {
  pinnedClient?: PinnedClientListItemResponse[]
  pinnedProgram?: PinnedProgramListItemResponse[]
  pinnedSquare?: PinnedSquareListItemResponse[]
  pinnedActivity?: PinnedActivityListItemResponse[]
}

export interface PinnedClientListItemResponse extends PinnedListItemResponseBase {
}

export interface PinnedListItemResponseBase {
  name?: string
  clientGuid: string
}

export interface PinnedProgramListItemResponse extends PinnedListItemResponseBase {
  programGuid: string
}

export interface PinnedSquareListItemResponse extends PinnedListItemResponseBase {
  programGuid: string
  squareGuid: string
  isCompleted: boolean
}

export interface PinnedActivityListItemResponse extends PinnedListItemResponseBase {
  programGuid: string
  squareGuid: string
  activityGuid: string
  isCompleted: boolean
}

export interface HealthDataResponseList {
  healthDataResponses?: HealthDataResponse[]
}

export interface HealthDataResponse {
  title?: string
  value?: number
  metrics?: HealthDataResponseItem[]
}

export interface HealthDataResponseItem {
  metric?: string
  count: number
  active: number
}

export interface HealthDataRequest {
  requestType: HealthDataRequestType
  filter?: HealthDataRequestFilter
}

export enum HealthDataRequestType {
 MembershipStatus = 1,
 KeySegmentation = 2,
 TotalLogins = 3,
 UniqueLogins = 4,
}

export interface HealthDataRequestFilter {
  roles?: Role[]
  registrationStatuses?: RegistrationStatus[]
  segmentGuids?: string[]
  segmentOptions: SelectedSegmentsOption
  startDate?: DateTime
  endDate?: DateTime
}

export interface PlatformNoticeResponse extends PlatformNoticeDisplayResponse {
  defaultTemplates?: Record<string, string>
  startDateTime?: DateTime
  lengthOfMaintenance?: number
  targetInSites: boolean
  targetModerators: boolean
  targetObservers: boolean
}

export interface PlatformNoticeDisplayResponse {
  noticeType: PlatformNoticeType
  startNoticeDateTime?: DateTime
  endNoticeDateTime?: DateTime
  customMessage?: string
}

export enum PlatformNoticeType {
 None = 0,
 DefaultMaintenance = 1,
 CustomNotice = 2,
}

export interface ListProgramItemResponse {
  list?: ProgramListItem[]
}

export interface ProgramListItem extends QueryModelBase {
  name?: string
  jobId?: string
  countSquares: number
  countActivities: number
  dateCreated: DateTime
  deletable: boolean
}

export interface ProgramDetailItemResponse {
  detail?: ProgramDetailItem
}

export interface ProgramDetailItem extends QueryModelBase {
  name?: string
  jobIdReminderEmail?: string
  creditsReminderEmail?: string
}

export interface ProgramCreditsDetailItem extends QueryModelBase {
  creditJobId?: string
  programGuid: string
  type: ProgramCreditsType
  totalCredits: number
  startDate?: DateTime
  creditsLeft: number
  dateExpired?: DateTime
  dateCreated: DateTime
}

export enum ProgramCreditsType {
 Native = 0,
 Decipher = 1,
}

export interface GetAvailableSquareJobsResponse {
  list?: SquareJobDto[]
}

export interface SquareJobDto {
  jobId?: string
}

export interface ProgramCreditsPerActivityTypeDetailItem extends QueryModelBase {
  programGuid: string
  forumDiscussion: number
  privateDiscussion: number
  lifeSnapshots: number
  lifeScenes: number
  survey: number
}

export interface ListJobItemResponse {
  list?: JobSelectionDto[]
}

export interface JobSelectionDto {
  jobId?: string
}

export interface ProgramCreditJobIdResponse {
  jobId?: string
  type: ProgramCreditsType
}

export interface ListLabelResponse {
  list?: Label[]
}

export interface Label {
  name?: string
  value?: string
}

export interface ListLanguageResponse {
  languages?: Language[]
}

export interface Language {
  code?: string
  name?: string
}

export interface ListCultureResponse {
  cultures?: Culture[]
}

export interface Culture {
  code?: string
  name?: string
}

export interface SquareInfoResponse {
  detail?: SquareInfoDetailItem
  isRightToLeft: boolean
  videoStorage: ClientVideoStorage
}

export interface SquareInfoDetailItem extends QueryModelBase {
  squareName?: string
  language?: string
  programGuid: string
  ssoEnabled: boolean
  isCompleted: boolean
  clientGuid: string
  clientName?: string
  subdomain?: string
  clientCode?: string
  ssoExternalLoginUrl?: string
  status: SquareStatus
  dateLastImageChange?: DateTime
  anonymous: boolean
  isPublicProfileEnabled: boolean
  jobIds?: string
  countries?: string[]
  adLogin: boolean
  adMigration: boolean
  adMigrationForced: boolean
  adFlowSuffix?: string
  shouldShowAppStoreLinksOnMobileWeb: boolean
  isOpenRegistration: boolean
}

export enum SquareStatus {
 Draft = 0,
 Scheduled = 1,
 Active = 2,
 Closed = 3,
 Archived = 4,
 Deleted = 5,
}

export interface ListCurrencyResponse {
  list?: CurrencyDto[]
}

export interface CurrencyDto {
  id: number
  guid: string
  name?: string
}

export interface ListCurrencyIdResponse {
  list?: number[]
}

export interface ConfigListResponse {
}

export interface SearchSelectionResponse {
  list?: SearchSelectionItem[]
}

export interface SearchSelectionItem {
  id: number
  name?: string
  guid: string
  programGuid?: string
  squareGuid?: string
  networkGuid?: string
  type: SearchType
  squareUrl?: string
  ssoEnabled: boolean
  status: SquareStatus
  isCompleted: boolean
  language?: string
  startDate?: DateTime
  createDate?: DateTime
  lastSelectedDate?: DateTime
}

export enum SearchType {
 Client = 0,
 Program = 1,
 Square = 2,
 Activity = 3,
 Network = 4,
}

export interface SquareInfoResponse2 {
  squareId?: number
  clientCode?: string
}

export interface SurveysResponse {
  surveys?: SurveyItemResponse[]
}

export interface SurveyItemResponse extends SegmentationTreeBase {
  activityName?: string
  questionLabel?: string
}

export interface SegmentationTreeBase {
  label?: string
  guidValue?: string
  targetValue?: string
  matchValue?: string
}

export interface QuestionsResponse {
  questions?: SurveyItemResponse[]
}

export interface AnswersForSegmentResponse {
  answers?: AnswerForSegment[]
}

export interface AnswerForSegment {
  answerGuid: string
  answerLabel?: string
  questionLabel?: string
}

export interface GetSegmentsResponse {
  id: number
  guid: string
  name?: string
  surveyAnswerGuids?: string[]
  precode?: string
  memberCount: number
}

export interface AnswersResponse {
  answers?: SurveyItemResponse[]
}

export interface SegmentationListResponseOfSegmentationListItem {
  segmentationListItem?: SegmentationListItem[]
}

export interface SegmentationListItem extends SegmentationBaseListItem {
  membersInSegmentation: number
  segments: number
  membersInCombinedSegments: number
  activityGuid?: string
  activityStatus?: SquareActivityStatus
  dateCreated: DateTime
  surveyQuestions?: SurveyQuestionListItem2[]
}

export interface SurveyQuestionListItem2 extends SurveyModelBase {
  isRemovedFromSurvey: boolean
  name?: string
  activityGuid?: string
  activityName?: string
  activityDateLastSynced?: DateTime
  activityStatus: SquareActivityStatus
}

export interface SegmentationBaseListItem extends QueryModelBase {
  squareGuid: string
  question?: string
  isStarred: boolean
  type: SegmentationType
  isDisabled: boolean
  isArchived: boolean
  isPublic: boolean
  segmentItems?: SegmentListItem[]
}

export enum SegmentationType {
 Survey = 0,
 Manual = 1,
 PlatformBehavior = 2,
 ActivityBehavior = 3,
 Profile = 4,
}

export interface SegmentListItem extends QueryModelBase {
  answer?: string
  segmentationGuid?: string
  segmentAnswer?: string
  surveyQuestionGuid?: string
  segmentGuid?: string
}

export interface SegmentationListResponseOfSegmentationForFilteringListItem {
  segmentationListItem?: SegmentationForFilteringListItem[]
}

export interface SegmentationForFilteringListItem extends SegmentationBaseListItem {
}

export interface SelectedSegmentsDetailsResponse {
  segments?: SegmentAnswerSelectionItemResponse[]
}

export interface SegmentAnswerSelectionItemResponse {
  id: number
  guid: string
  segmentationGuid: string
  segmentGuid: string
  question?: string
  answer?: string
  answerGuid?: string
  lastUpdated: DateTime
  membersInSegment: number
  membersInSegmentation: number
}

export interface KeySegmentationListResponse {
  keySegmentations?: KeySegmentationListItem[]
}

export interface KeySegmentationListItem extends QueryModelBase {
  squareGuid: string
  question?: string
  isFavorite: boolean
  surveyQuestions?: SurveyQuestionListItem2[]
  keySegments?: KeySegmentListItem[]
}

export interface KeySegmentListItem extends QueryModelBase {
  segmentationGuid: string
  answer?: string
  surveyAnswers?: SurveyAnswerListItem[]
}

export interface SegmentParticipantsForSegmentationsResponse {
  segmentParticipants?: SegmentParticipantsListItem[]
}

export interface SegmentParticipantsListItem extends QueryModelBase {
  segmentationGuid: string
  segmentGuid?: string
  answer?: string
  squareParticipantId?: number
  squareParticipantGuid?: string
  status?: RegistrationStatus
  suspended?: boolean
  role?: Role
}

export type MasterAppListResponse = MasterAppItem[]

export interface MasterAppItem {
  guid: string
  name?: string
  appReference?: string
  squares?: MasterAppItemSquare[]
}

export interface MasterAppItemSquare {
  clientCode?: string
  squareUrl?: string
}

export interface SquareActivitiesResponse {
  squareGuid?: string
  squareName?: string
  activities?: SquareActivity[]
}

export interface SquareActivity {
  guid?: string
  title?: string
  teaser?: string
  teaserThumbnail?: string
  callToAction?: string
  callToActionUrl?: string
  intro?: string
  introCaption?: string
  introStimuli?: SquareActivityStimulus[]
  startDate?: DateTime
  endDate?: DateTime
  theme?: string
  reward?: number
}

export interface SquareActivityStimulus {
  thumbnail?: string
  url?: string
}

export interface GetAppleAppIdResponse {
  appleAppIds?: string[]
}

export interface GetAppAndroidPackageFingerprintsResponse {
  packageFingerprints?: Record<string, string[]>
}

export interface GetAppCustomUriDetailsResponse {
  appName?: string
  customUriScheme?: string
}

export interface ListSquareItemResponse {
  list?: SquareListItem[]
}

export interface SquareListItem extends QueryModelBase {
  name?: string
  programGuid: string
  language?: string
  languageName?: string
  countActivities: number
  startDate?: DateTime
  jobIds?: string[]
  status: SquareStatus
  statusString?: string
  isCompleted: boolean
  dateCreated: DateTime
  programName?: string
  url?: string
  showArchivedButton: boolean
}

export interface SquareDetailItem extends QueryModelBase {
  name?: string
  jobIds?: SquareJobDetailItem[]
  language?: string
  configuredSavingUpRewardCurrencies?: string[]
  programId: number
  networkId?: number
  startDate?: DateTime
  startDateOffset: number
  dateCreated: DateTime
  status: SquareStatus
  statusString?: string
  url?: string
  ssoEnabled: boolean
  isCompleted: boolean
  welcomeBodyResearcher?: string
  welcomeBodyObserver?: string
  welcomeBodyParticipant?: string
  digestEmailFrequencyDays: number
  digestMailTime: string
  digestEmailOffset: number
  isDigestEmailEnabled: boolean
  ssoExternalLoginUrl?: string
  hasSsoData: boolean
  closureMessage?: string
  anonymous: boolean
  isPublicProfileEnabled: boolean
  hasBinding: boolean
  hasActivities: boolean
  adLogin: boolean
  adMigration: boolean
  adFlowSuffix?: string
  shouldShowAppStoreLinksInEmailFooter: boolean
  shouldForceMobileApp: boolean
  shouldShowAppStoreLinksOnMobileWeb: boolean
  passwordLength: number
  hasStrongPasswordEnabled: boolean
  allowPiiInExports: boolean
}

export interface SquareJobDetailItem extends QueryModelBase {
  jobId?: string
  squareGuid: string
  startDate?: DateTime
  commitment?: number
  status: JobIdStatus
  isSelfServe: boolean
}

export enum JobIdStatus {
 Legacy = 0,
 NotStarted = 1,
 Active = 2,
 Elapsed75Perc = 3,
 Elapsed90Perc = 4,
 Expired = 5,
 Paused = 6,
}

export interface SquareDetailItemResponse {
  detail?: SquareDetailItem
  countries?: CountryResponse[]
  disabledSquareUrls?: string[]
  pendingSquareUrl?: string
}

export interface CountryResponse {
  iso?: string
  englishName?: string
  currencyIso?: string
  segmentGuid: string
  units: number
  value: number
}

export interface GetSquareAppScreenerInfoResponse {
  isLinkedToWhiteLabeledApp: boolean
  linkedScreenerGuid?: string
  linkedScreenerName?: string
}

export interface SquareSelectionResponse {
  list?: SquareSelectionItem[]
}

export interface SquareSelectionItem extends QueryModelBase {
  name?: string
  programGuid: string
  networkGuid?: string
  url?: string
}

export interface ConnectProjectDetailItem extends QueryModelBase {
  squareGuid: string
  projectUrl?: string
}

export interface MobileAppGetLabelsResponse {
  culture?: string
  labels?: Label[]
}

export interface ListAllSquaresItemResponse {
  squares?: SquareListItem[]
}

export interface SquareDesignDetailItemResponse {
  squareGuid: string
  squareDesign?: SquareDesignListItem
  pageDesign?: PageDesignListItem[]
  customCss?: string
}

export interface SquareDesignListItem extends QueryModelBase {
  baseColor1?: string
  navigationBarLeft?: string
  navigationBarRight?: string
  navigationBarText?: string
  backgroundColor?: string
  squareGuid: string
  hasCustomCss: boolean
  hasCustomEmailCss: boolean
  hasLogoTopBar: boolean
  hasHeaderLogo: boolean
  hasHeaderDesktop: boolean
  hasHeaderMobile: boolean
  hasHeaderEmail: boolean
  hasBackground: boolean
  hasActivationEmailImage: boolean
  hasActivationReminderEmailImage: boolean
  hasAlmostInactiveEmailImage: boolean
  hasChangeEmailImage: boolean
  hasDigestEmailImage: boolean
  hasDownloadVoucherEmailImage: boolean
  hasFailedStimuliEmailImage: boolean
  hasForgotPasswordMultipleSquaresEmailImage: boolean
  hasForgotPasswordEmailImage: boolean
  hasIncentiveEmailImage: boolean
  hasNewsletterEmailImage: boolean
  hasProbingQuestionsEmailImage: boolean
  hasReactivationEmailImage: boolean
  hasReplyAcceptedEmailImage: boolean
  hasReplyWithAnswerRequiredEmailImage: boolean
  hasResearchActivityIncompleteEmailImage: boolean
  hasResearchActivityInviteEmailImage: boolean
  hasResearchActivityReminderEmailImage: boolean
  hasSavingUpRewardsCorrectionEmailImage: boolean
  hasSquareClosedPointsExpirationEmailImage: boolean
  hasWelcomeEmailImage: boolean
  hasCommunicationCodeEmailImage: boolean
  logoTopBarUrl?: string
  headerLogoUrl?: string
  headerDesktopUrl?: string
  headerMobileUrl?: string
  headerEmailUrl?: string
  backgroundUrl?: string
  activationEmailUrl?: string
  activationReminderEmailUrl?: string
  almostInactiveEmailUrl?: string
  changeEmailImageUrl?: string
  digestEmailUrl?: string
  downloadVoucherEmailUrl?: string
  failedStimuliEmailUrl?: string
  forgotPasswordMultipleSquaresEmailUrl?: string
  forgotPasswordEmailUrl?: string
  incentiveEmailUrl?: string
  newsletterEmailUrl?: string
  probingQuestionsEmailUrl?: string
  reactivationEmailUrl?: string
  replyAcceptedEmailUrl?: string
  replyWithAnswerRequiredEmailUrl?: string
  researchActivityIncompleteEmailUrl?: string
  researchActivityInviteEmailUrl?: string
  researchActivityReminderEmailUrl?: string
  savingUpRewardsCorrectionEmailUrl?: string
  squareClosedPointsExpirationEmailUrl?: string
  welcomeEmailUrl?: string
  communicationCodeUrl?: string
  dateModified?: DateTime
}

export interface PageDesignListItem extends QueryModelBase {
  pageGuid: string
  pageName?: string
  color?: string
}

export interface SquareAppDesignResponse {
  backgroundColorHex?: string
  navigationBarLeftColorHex?: string
  navigationBarRightColorHex?: string
  navigationBarBellColorHex?: string
  buttonColorHex?: string
  headerBlobUrl?: string
  logoBlobUrl?: string
}

export interface ListSquareTimelineCardsResponse {
  list?: ICardListItem[]
  hasMoreCards: boolean
}

export interface ICardListItem {
}

export interface SquareAppActivitiesResponse {
  activities?: AppActivity[]
}

export interface AppActivity {
  label?: string
  activityUrl?: string
  isNew: boolean
  activityGuid: string
}

export interface ActivitySelectionResponse {
  list?: ActivitySelectionItem[]
}

export interface ActivitySelectionItem extends QueryModelBase {
  name?: string
  squareGuid: string
  startDate?: DateTime
  endDate?: DateTime
  isPublished: boolean
  isArchived: boolean
  format: SquareActivityFormat
  status: SquareActivityStatus
  surveyId?: string
  themeId?: number
  sequenceRole: ActivitySequenceRole
}

export interface CheckSquareNameIsDuplicateResponse {
  isDuplicate: boolean
}

export interface SquareParticipantForLoggedInInSitesUserResponse {
  squareParticipantGuid: string
  email?: string
}

export interface ActivityThemeListItemResponse {
  guid?: string
  sortOrder?: number
  name?: string
  activitiesCount: number
  isDefaultTheme: boolean
}

export interface ImpersonateUserListResponse {
  guid: string
  username?: string
  hasProfileImage: boolean
  role: Role
  dateModified?: DateTime
}

export interface SplashMessageDetailItem extends QueryModelBase {
  squareGuid: string
  isEnabled: boolean
  message?: string
  agreeLabel?: string
  disagreeLabel?: string
  type: SplashMessageType
}

export enum SplashMessageType {
 Undefined = 0,
 Agreement = 1,
 SquareClosure = 2,
}

export interface SquareUrlDto {
  id: number
  guid: string
  clientCode?: string
  squareId: number
  url?: string
  status: SquareUrlStatus
}

export enum SquareUrlStatus {
 Disabled = 0,
 Pending = 1,
 Enabled = 2,
}

export interface GetSquareJobsResponse extends SquareJobDetailItem {
  squareName?: string
}

export interface GetSquaresWithMobileScreenerForAppResponse {
  guid: string
  name?: string
  language?: string
  activityGuid: string
  partnerId: number
  backgroundVariables?: BackgroundVariableValuePair[]
  country?: Record<string, string>
}

export interface BackgroundVariableValuePair {
  variable?: string
  value?: string
}

export interface SquareLandingPageConfigResponse {
  hasTopLogo: boolean
  hasBackground: boolean
  title?: string
  text?: string
  adFlowSuffix?: string
}

export interface SquareSocialLoginConfigResponse {
  types?: SquareSocialLoginType[]
}

export interface SquareSocialLoginType {
  type: SocialLoginType
  name?: string
  title?: string
  enabled: boolean
}

export interface UserMigrationConfigResponse {
  adMigrationPageEnabled: boolean
  adMigrationPageForced: boolean
}

export interface TargetingResponse {
  randomlySelected?: number
  excludeParticipantsInvitedSinceWeeks?: number
  targeted: number
  targetedFrom: number
  matches?: number
  targetingType: TargetingType
  targetingFilterOption: TargetingFilterOption
  targetingFilterType: TargetingFilterType
  status: TargetingStatus
  items?: TargetingItemResponse[]
  squareParticipantIds?: number[]
  hasQuota: boolean
  dateLastFeasibilityCheck?: DateTime
  targetingQuota?: TargetingQuota
  targetingStatus: TargetingStatus
}

export enum TargetingType {
 Activity = 0,
 Communication = 1,
}

export enum TargetingStatus {
 Awaiting = 0,
 InProgress = 1,
 Done = 2,
}

export interface TargetingItemResponse {
  id: number
  segmentationGuid?: string
  segmentGuids?: string[]
  targetingFilterOption: TargetingFilterOption
}

export interface MembersInSegmentsResponse {
  membersTotal: number
  membersTargeted: number
  membersStarted: number
  membersByTargeting?: MembersCountByTargeting[]
}

export interface MembersCountByTargeting {
  id: number
  membersCount: number
}

export interface MembersInSegmentsRequest {
  targetingGuid?: string
  parentActivityGuid?: string
  refGuid: string
  targetingType: TargetingType
  targetingFilterOption: TargetingFilterOption
  targetingFilterType: TargetingFilterType
  randomlySelected?: number
  excludeParticipantsInvitedSince?: DateTime
  targetedMemberIds?: number[]
  items?: TargetingItemResponse[]
}

export interface SampleActivityListItemResponse {
  removed: SampleRemovedReasons
  squareParticipantGuid: string
  sampleGuid: string
  status: SampleStatus
  squareParticipantRole: Role
}

export interface SampleDetailItemCommunicationDetailItem extends SampleDetailItem {
  communicationGuid: string
  removed: SampleRemovedReasons
}

export interface SampleDetailItem extends QueryModelBase {
  sampleGuid: string
  isTest: boolean
  squareParticipantGuid: string
  squareParticipantRole: Role
}

export interface SampleParticipationListItemResponse {
  squareParticipantGuid: string
  lastSampledForActivity?: DateTime
  completionRate: number
  avgCompletionTimeMinutes: number
}

export interface GetSampleParticipationRequest {
  squareParticipantGuids?: string[]
}

export interface GetActivitySegmentTargetForSegmentationResponse {
  activitySegmentTargetList?: ActivitySegmentTargetListItem[]
  uniqueNrOfCompletes: number
}

export interface ActivitySegmentTargetListItem extends QueryModelBase {
  activityId: number
  activityGuid: string
  segmentId: number
  segmentGuid: string
  segmentationGuid: string
  segmentationName?: string
  answer?: string
  target: number
  qualified: number
  inProgress: number
  dateModified?: DateTime
  available: number
}
