import { concat, forEach, noop, random, remove } from 'lodash-es';
import { Signalr } from 'lib/signalr';

import { EventPubSub } from './event-pub-sub';
import { SquareStatus, StimuliUploadState } from '@/common/constants/enums';
import { BUILD_VERSION, ENABLE_DEBUG, SIGNALR_URL } from '@/common/env.config';
import { useAuthenticationStore } from '@/store/authentication-store';

import { AttachmentUploaded, Emitter, HubRegistration, HubRegistrations, StimuliUploaded } from './events.types';
import { PostChanged } from '@ui/components/user-notification-base/user-notification-base.types';
import { ProfileActivityDetailItemResponse } from '@api/models/query';
import { getTokenForSignalR } from '@api/services/query/default/AuthorizationService';

import router from '@/router';

// #region Events
export interface UserEvent {
  identifier: string;
  channelType: number | null;
  targetGuid: string;
}

interface StimulusTranscriptionStatusChanged {
  stimulusGuid: string;
  status: StimuliUploadState;
  url?: string;
  thumbnailUrl?: string;
  discussionGuid: string;
}

const hubRegistrations: HubRegistrations<unknown> = {};

export const createEvent = <T>(hubName: string, signalrEvent: string, shouldResolveInitialValueOnSubscribe = true): EventPubSub<T> => {
  if (!hubRegistrations[hubName]) {
    hubRegistrations[hubName] = { events: [] };
  }
  const pubSub = new EventPubSub<T>(signalrEvent, shouldResolveInitialValueOnSubscribe);
  (hubRegistrations[hubName] as HubRegistration<T>).events.push(pubSub);
  return pubSub;
};

// Create all events
export const profileActivitiesChangedEvent =
  createEvent<ProfileActivityDetailItemResponse[]>('Notifications', 'profileActivitiesChanged');
export const progressChangedEvent = createEvent<number>('Notifications', 'progressChanged');
export const userNotificationEvent = createEvent<void>('Notifications', 'UserNotification');
export const timeLineChangedEvent = createEvent<void>('Notifications', 'TimeLineChanged');
export const pHealthDummyEvent = createEvent<void>('PlatformHealth', 'pHealthDummyEvent');
export const pagesChangedEvent = createEvent<void>('Notifications', 'PagesChanged');
export const todoActivitiesChangedEvent = createEvent<number>('Notifications', 'todoActivities');
export const suspendedNotificationEvent = createEvent<void>('Notifications', 'suspendedNotification');
export const showVideoThumbnailEvent = createEvent<StimuliUploaded>('Notifications', 'ShowVideoThumbnail');
export const showPhotoThumbnailEvent = createEvent<StimuliUploaded>('Notifications', 'ShowPhotoThumbnail');
export const updateAttachmentUrlEvent = createEvent<AttachmentUploaded>('Notifications', 'UpdateAttachmentUrl');
export const discussionActivityCompleteEvent = createEvent<void>('Notifications', 'discussionActivityComplete');
export const forumConversationChangedEvent = createEvent<string>('Notifications', 'forumConversationChanged');
export const replyAddedEvent = createEvent<string>('Notifications', 'replyAdded');
export const loungeTopicAdded = createEvent<string>('Notifications', 'loungeTopicAdded');
export const discussionChangedEvent = createEvent<string>('Notifications', 'discussionChanged');
export const discussionNewChangedEvent = createEvent<PostChanged>('Notifications', 'discussionNewChanged');
export const userLoggedOutEvent = createEvent<void>('Notifications', 'userLoggedOut');
export const communicationSampleCompleteEvent = createEvent<string>('Notifications', 'communicationSampleComplete');
export const squareStatusChanged =
  createEvent<{ Status: SquareStatus }>('Notifications', 'squareStatusChanged');
export const showEngagementCardVideoThumbnailEvent =
  createEvent<StimuliUploaded>('Notifications', 'ShowEngagementCardVideoThumbnail');
export const connected = createEvent<boolean>('Notifications', 'SignalrConnected');
export const forcedLogout = createEvent<boolean>('Notifications', 'forcedLogout');
export const stimulusUploadStatusUpdatedEvent = createEvent<StimulusTranscriptionStatusChanged>('Notifications', 'stimulusTranscriptionStatusChanged');
// #endregion

// #region Connection
let connectionId: string | null = null;
let pendingCalls: Array<() => void> = [];
let disconnect = noop;

const onDisconnect: Array<() => void> = [];
const connection = Signalr()(SIGNALR_URL, { useDefaultPath: false, logging: ENABLE_DEBUG });

export const registerHubs = async () => {
  forEach(hubRegistrations, (registrations, hubName) => {
    registrations.proxy = connection.createHubProxy(hubName) as Emitter<unknown>;

    for (const pubsub of registrations.events) {
      const off = pubsub.useEmitter(registrations.proxy);
      onDisconnect.push(off);
    }
  });
};

export const connect = async () => {
  disconnect();
  let updateTokenInQueryString = async () => {
    connection.qs.token = await getTokenForSignalR();
    if (connection.qs && connection.qs.token === '') {
      throw new Error('Empty token');
    }
  };
  const authStore = useAuthenticationStore();

  let connectInternal = async () => {
    connection.qs = { version: BUILD_VERSION };
    if (authStore.impersonate?.squareParticipantGuid) {
      connection.qs.ImpersonateGuid = authStore.impersonate.squareParticipantGuid;
    }
    await updateTokenInQueryString();
    connection.start({ withCredentials: false, transport: ['webSockets', 'serverSentEvents', 'longPolling'] })
      .done(() => {
        connectionId = connection.id;
        connected.next(true);
        forEach(hubRegistrations, (reg) => {
          forEach(reg.events, (event) => event.resetSubscriptions());
        });

        // restore groups
        for (const groupName of groups) {
          addUserToGroup(groupName);
        }

        for (const pending of pendingCalls) {
          pending();
        }
        pendingCalls = [];
      })
      .fail((e: { message: string }) => {
        // There are too many retries to bother users with a message, maybe in future UX could be improved
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };
  await connectInternal();

  // retry connection if there was a succesfull connection before !!
  let connectAttempts = 1;
  const maxConnectAttempts = 5;
  let timeoutInSeconds = 5;
  connection.disconnected(() => {
    if (connectInternal && connectAttempts < maxConnectAttempts) {
      connectAttempts++;
      // Calculate timeout between two attempts
      // First  will be between 1        and 12 seconds
      // Second                 (First)      24
      // Third                  (Second)     36
      // Fourth                 (Third)      48
      // Fifth                  (Fourth)     60
      timeoutInSeconds = random(timeoutInSeconds, 60) * connectAttempts / maxConnectAttempts;
      setTimeout(async () => {
        if (connectInternal) {
          // if there is a new connection set then we also need a pageVisitCall for the currentpage
          const pageVisitCall = () => {
            forwardCallToHub('PlatformHealth', 'pageVisited', router.currentRoute.value.path);
          };

          const isForwardCallPending = pendingCalls.some((fn) => fn.toString() === pageVisitCall.toString());

          if (!isForwardCallPending) {
            pendingCalls.push(pageVisitCall);
          }
          await connectInternal();
        }
      }, timeoutInSeconds * 1000);
    }
  });

  disconnect = () => {
    for (const off of onDisconnect) {
      off();
    }
    pendingCalls = [];
    connection.stop();
    connectInternal = () => Promise.resolve();
    updateTokenInQueryString = () => Promise.resolve();
    delete connection.qs;
    disconnect = noop;
    connectionId = null;
  };
};

export const getConnectionId = () => connectionId;
// #endregion

// #region Event handling
const forwardCallToHub = (hubName: string, method: string, ...args: unknown[]) => {
  const hubRegistration = hubRegistrations[hubName];
  if (!hubRegistration || !hubRegistration.proxy) {
    return Promise.reject(`no hub registered with name ${hubName}`);
  }

  args = concat([method], args);

  const signalrCall = () => {
    hubRegistration.proxy?.invoke(...args).done(() => {
      Promise.resolve();
    }).fail((error) => {
      Promise.reject(error);
    });
  };

  // if the connection is down, keep the call in a queue
  // unfortunately we lose the exact timestamp
  if (hubRegistration.proxy.connection.state !== 1) {
    pendingCalls.push(signalrCall);
  } else {
    signalrCall();
  }
};

export const elementClicked = (event: UserEvent) => sendEvent(
  'PlatformHealth',
  'elementClicked',
  event.identifier,
  event.channelType,
  event.targetGuid);

export const elementViewed = (event: UserEvent) => sendEvent(
  'PlatformHealth',
  'elementViewed',
  event.identifier,
  event.channelType,
  event.targetGuid);

export const pageVisited = (path: string) =>
  sendEvent('PlatformHealth', 'pageVisited', path);

const sendEvent = (hubName: string, methodName: string, ...args: unknown[]) => {
  const forwardCall = () => {
    forwardCallToHub(hubName, methodName, ...args);
  };

  if(!connectionId) {
    pendingCalls.push(forwardCall);
    return;
  }

  forwardCall();
};
// #endregion

// #region Groups
const groups: string[] = [];
const onUserAddedToActivity: (groupName: string) => void = noop;
const onUserRemovedFromActivity: (groupName: string) => void = noop;

export const addUserToGroup = (groupName: string, isActivity?: boolean) => {
  const forwardCall = () => {
    forwardCallToHub('Notifications', 'addUserToGroup', groupName);
    if (!groups.includes(groupName)) {
      groups.push(groupName);
    }
  };

  if (!connectionId) {
    pendingCalls.push(forwardCall);
  } else {
    forwardCall();
  }

  if (isActivity) {
    onUserAddedToActivity(groupName);
  }
};

export const removeUserFromGroup = (groupName: string, isActivity?: boolean) => {
  const forwardCall = () => {
    forwardCallToHub('Notifications', 'removeUserFromGroup', groupName);
    remove(groups, (item) => item === groupName);
  };

  if (!connectionId) {
    pendingCalls.push(forwardCall);
  } else {
    forwardCall();
  }

  if (isActivity) {
    onUserRemovedFromActivity(groupName);
  }
};
// #endregion
